import {useCallback, useEffect, useRef, useState} from 'react';

import {isUndefined} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

import {SkeletonLoader} from '~/shared/components/Loaders';
import {scrollWindowTop} from '~/shared/utils/general';
import {PageHeader} from '~/shared/components/header';
import actions from '~/shared/store/actions';
import {getCurrentLocation} from '~/shared/router';
import {ScooberJobStatuses, SCOOBER_INTERVAL_TIME} from '~/shared/consts/scooberConsts';
import {EMPTY_OBJECT} from '~/shared/consts/commonConsts';
import {
  selectIsScooberJobCanceled,
  selectIsTrackerUrlEmpty,
  selectOrderSuccessData,
  selectUserLastOrderScooberJob,
  selectIsOrderBarcodeEnable,
} from '~/shared/store/selectors';
import {createLogger} from '~/shared/logging';
import {navigateToDefaultStartPage} from '~/shared/services/navigation';
import {flexColumn} from '~/shared/theme/FlexLayout';
import {media} from '~/shared/theme/media';
import {Payments, BarcodeOrderIdArea} from '~/shared/components/OrderCompleteSections';
import {useIsIOS} from '~/shared/hooks/deviceInfo';
import {DeliveryMethods} from '~/shared/consts/restaurantConsts';
import {isDonationRestaurant} from '~/shared/utils/donationRestaurants';
import {handleRefreshToken, is401Error} from '~/shared/services/auth';
import {RestaurantBusinessTypeName} from '~/shared/store/models';
import RestaurantMultipassCard from '~/common/components/RestaurantMultipassCard';
import {Section} from '~/shared/components/OrderCompleteSections/parts';

import useIsShowMap from './hooks/useIsShowMap';
import {BarcodeOrDetailsBlock} from './BarcodeOrDetailsBlock';
import {BottomBlock} from './BottomBlock';
import {ScooberAndOrderBlock} from './ScooberAndOrderBlock';
import OrderSuccessLoader from './OrderSuccessLoader';
import OrderSuccessViewFooter from './OrderSuccessViewFooter';

const logger = createLogger('OrderSuccess');

const MOBILE_FOOTER = 66;
const DESKTOP_FOOTER = 170;

const BackgroundBoxOverIframeWidth = 274;
const BackgroundBoxOverIframeColor = '#f8f5f2';

const Root = styled.div`
  margin-top: ${({theme}) => theme.header.height}px;
  padding: 8px 2px;
  min-height: calc(100vh - ${({theme}) => theme.header.height + DESKTOP_FOOTER}px);
  ${flexColumn};
  justify-content: flex-start;
  ${({isIos}) => !isIos && 'overflow-x: hidden'};
  ${media.minMobile`
    min-height: calc(100vh - ${({theme}) => theme.header.height + MOBILE_FOOTER}px);
    padding-right: calc(50% - ${({theme}) => theme.orderSuccess.body.width / 2}px);
    padding-left: calc(50% - ${({theme}) => theme.orderSuccess.body.width / 2}px);
  `};
`;

const BackgroundBoxOverIframeLeft = styled.div`
  ${media.minLargeTablet`
    position: absolute;
    width: calc(50% - ${BackgroundBoxOverIframeWidth}px);
    height: 144px;
    top: 450px;
    left: 0;
    background-color: ${BackgroundBoxOverIframeColor};
    z-index: 10;
`};
`;

const BackgroundBoxOverIframeRight = styled.div`
  ${media.minLargeTablet`
      position: absolute;
      width: calc(50% - ${BackgroundBoxOverIframeWidth}px);
      height: 144px;
      top: 450px;
      right: -2px;
      background-color: ${BackgroundBoxOverIframeColor};
      z-index: 10;
  `}
`;

const RestaurantMultipassCardWrapper = styled(Section)`
  padding: 18px 24px;
  margin-top: 8px;
  ${flexColumn}
`;

const OrderSuccessView = ({allowInit}) => {
  const [scooberJobFetchFinished, setScooberJobFetchFinished] = useState(false);
  const isIos = useIsIOS();
  const {routeParams} = getCurrentLocation();
  const dispatch = useDispatch();
  const scooberOrderInterval = useRef();

  const orderSuccessData = useSelector(selectOrderSuccessData) || EMPTY_OBJECT;
  const isStatusCanceled = useSelector(selectIsScooberJobCanceled);
  const isTrackerUrlEmpty = useSelector(selectIsTrackerUrlEmpty);
  const isOrderBarcodeEnable = useSelector(selectIsOrderBarcodeEnable);

  const {trackerUrl, status} = useSelector(selectUserLastOrderScooberJob) || EMPTY_OBJECT;

  const {orderId: orderIdFromRouteParams} = routeParams;

  const {address, restaurant, dishToPresent, orderStatus, deliveryMethod} = orderSuccessData;

  const isScoober = restaurant?.isScoober;
  const isVoucherCard = restaurant?.businessType === RestaurantBusinessTypeName.VoucherCard;
  const isShowMap = useIsShowMap();

  const isShowBackgroundBoxIframeDivs = trackerUrl && !isTrackerUrlEmpty && !isShowMap;
  const isEnlargeTopMargin = trackerUrl && !isTrackerUrlEmpty && !isStatusCanceled && isScoober;

  const fetchScooberData = useCallback(async () => {
    try {
      await dispatch(actions.getUserLastOrderScooberJob(orderIdFromRouteParams));
    } catch (error) {
      logger.error('Error while trying to getUserLastOrderScooberJob', {error});
      if (is401Error(error)) {
        await handleRefreshToken(error, fetchScooberData);
        return;
      }
      navigateToDefaultStartPage();
    } finally {
      setScooberJobFetchFinished(true);
      const clearScooberInterval = status === ScooberJobStatuses.Canceled || status === ScooberJobStatuses.EatingTime;

      if (clearScooberInterval) {
        clearInterval(scooberOrderInterval.current);
      }
    }
  }, [dispatch, orderIdFromRouteParams, status, setScooberJobFetchFinished]);

  const shouldFetchScooberData = restaurant?.isScoober;

  useEffect(() => {
    scrollWindowTop({disabledAnimation: true});
    if (shouldFetchScooberData) {
      scooberOrderInterval.current = setInterval(fetchScooberData, SCOOBER_INTERVAL_TIME);
    }
    return () => clearInterval(scooberOrderInterval.current);
  }, [shouldFetchScooberData, fetchScooberData]);

  useEffect(() => {
    if (shouldFetchScooberData && allowInit) {
      fetchScooberData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchScooberData, allowInit]);

  const shouldShowLoader = isUndefined(orderSuccessData) || isUndefined(address) || isUndefined(restaurant) || (isScoober && !scooberJobFetchFinished);
  const showAgeRestrictionDisclaimer = dishToPresent?.some(({ageRestricted}) => ageRestricted);

  const isDonation = restaurant && isDonationRestaurant(restaurant.restaurantId);
  const barcodeOrderId = (deliveryMethod?.toLowerCase() === DeliveryMethods.PICKUP && isOrderBarcodeEnable && orderStatus !== 'Canceled') ? orderIdFromRouteParams : undefined;

  return (
    <Root isIos={isIos}>
      {isShowBackgroundBoxIframeDivs && (
        <>
          <BackgroundBoxOverIframeLeft />
          <BackgroundBoxOverIframeRight />
        </>
      )}
      <PageHeader />

      <SkeletonLoader shouldShowLoader={shouldShowLoader} LoaderComponent={OrderSuccessLoader}>
        {orderSuccessData && (
          <>
            <ScooberAndOrderBlock isDonation={isDonation} isVoucherCard={isVoucherCard} />
            {!isVoucherCard && (
              <>
                {barcodeOrderId && <BarcodeOrderIdArea barcodeOrderId={barcodeOrderId} />}
                <BarcodeOrDetailsBlock isDonation={isDonation} />
              </>
            )}
            {isVoucherCard && (
              <RestaurantMultipassCardWrapper>
                <RestaurantMultipassCard allowInit={allowInit} orderData={orderSuccessData}/>
              </RestaurantMultipassCardWrapper>
            )}
            <Payments
              showAgeRestrictionDisclaimer={showAgeRestrictionDisclaimer}
              billingLines={orderSuccessData?.billingLines}
              payments={orderSuccessData?.orderPayments}
              loyaltyProgramPoints={orderSuccessData?.numOfLoyaltyProgramPointsEarned}
              isBarCodeOrder={Boolean(barcodeOrderId)}
              isVoucherCard={isVoucherCard}
            />
            <BottomBlock isDonation={isDonation} />
          </>
        )}
      </SkeletonLoader>
      <OrderSuccessViewFooter isEnlargeTopMargin={isEnlargeTopMargin} />
    </Root>
  );
};

export default OrderSuccessView;
