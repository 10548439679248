import deliverySvgUrl from '~/assets/images/delivery.svg';
import pickupSvgUrl from '~/assets/images/pickup.svg';
import tositSvgUrl from '~/assets/images/tosit.svg';
import {baseTheme} from '~/shared/theme';

export const COUPONS_CONFIG = {
  delivery: {
    background: baseTheme.colors.primary,
    image: deliverySvgUrl,
    title: 'with_delivery',
  },
  pickup: {
    background: '#d84768',
    image: pickupSvgUrl,
    title: 'with_pickUp',
  },
  sitting: {
    background: '#1ca0a0',
    image: tositSvgUrl,
    title: 'on_sitting',
  },
};

export type CouponsConfigKey = keyof typeof COUPONS_CONFIG;
