import {memo, useEffect, useState} from 'react';

import {useSelector} from 'react-redux';
import styled from 'styled-components';

import {flipOnLTR} from '~/shared/theme/utils';
import actions from '~/shared/store/actions';
import {LayoutWithHeader} from '~/shared/components/LayoutWithHeader';
import {getLocalizationService} from '~/shared/services/localisationService';
import {useIsMinLargeTablet} from '~/shared/hooks/deviceInfo';
import {selectCurrentRestaurant} from '~/shared/store/selectors';
import {body12Normal} from '~/shared/theme/typography';
import store from '~/shared/store';
import {getCurrentLocation} from '~/shared/router';
import {handleRefreshToken, is401Error} from '~/shared/services/auth';

import Coupons from './Coupons';

const Root = styled.div``;

const FooterText = styled.div`
  margin-top: 5px;
  ${body12Normal};
  line-height: 16px;
  padding-bottom: 10px;
  ${flipOnLTR`
    text-align: right;
    padding-right: 10px;
  `}
`;

type RestaurantCouponsProps = {
  closeModal?: () => void;
  showHeader: boolean;
};

const RestaurantCoupons = ({closeModal, showHeader = true}: Readonly<RestaurantCouponsProps>) => {
  const [embeddedError, setEmbeddedError] = useState(false);
  const {query} = getCurrentLocation();
  const {embedded} = query;
  const {t} = getLocalizationService();

  const currentRestaurant = useSelector(selectCurrentRestaurant);
  const isMinLargeTablet = useIsMinLargeTablet();

  useEffect(() => {
    const getCurrentRestaurantCouponFullInfo = async () => {
      if (currentRestaurant) {
        try {
          await store.dispatchThunk(
            actions.getRestaurantCouponFullInfo({
              restaurantId: currentRestaurant.id,
            }),
          );
        } catch (error) {
          if (is401Error(error)) {
            if (embedded) {
              setEmbeddedError(true);
              return;
            }

            handleRefreshToken(error, getCurrentRestaurantCouponFullInfo);
          }
        }
      }
    };

    getCurrentRestaurantCouponFullInfo();
  }, [currentRestaurant, embedded]);

  return (
    <Root>
      <LayoutWithHeader onClick={closeModal} titleKey="your_discounts" showHeader={isMinLargeTablet && showHeader}>
        {!embeddedError && <Coupons />}
        <FooterText>{embeddedError ? t('opss_something_went_wrong') : `*${t('no_double_discounts')}`}</FooterText>
        <FooterText>
          {embeddedError
            ? t('reload_the_app')
            : `*${t('sales_content_and_dates_may_change_from_time_to_time_by_the_restaurant')}`}
        </FooterText>
        {!embeddedError && (
          <FooterText>
            {`*${t(
              'coupons_date_and_times_disclaimer',
            )}`}
          </FooterText>
        )}
      </LayoutWithHeader>
    </Root>
  );
};

export default memo(RestaurantCoupons);
