import styled from 'styled-components';
import {useSelector} from 'react-redux';

import {SkeletonLoader, Skeleton as _Skeleton} from '~/shared/components/Loaders';
import {media, flipOnLTR} from '~/shared/theme/utils';
import {
  selectCurrentRestaurantCouponsLoaded,
  selectCurrentRestaurantCouponsLoading,
  selectCurrentRestaurantCoupons,
} from '~/shared/store/selectors';
import {body13Bold} from '~/shared/theme/typography';
import {flexColumn, flexColumnCenterVertically} from '~/shared/theme/FlexLayout';
import {getLocalizationService} from '~/shared/services/localisationService';
import {RestaurantCouponHappyHour, RestaurantDiscountCoupon} from '~/shared/store/models';

import {COUPONS_CONFIG, CouponsConfigKey} from '../constants';

import Coupon from './Coupon';

const CouponsList = styled.div`
  height: 100%;
`;

const CouponsTitle = styled.div`
  ${body13Bold};
  font-weight: bolder;
  color: ${({theme}) => theme.couponsModal.subHeadersColor};
  ${flipOnLTR`
    padding-right: 5px;
  `}
  ${media.minMobile`
      text-align: center;
      height: 45px;
  `}
`;

const CouponsWrapper = styled.div`
  display: flex;
  margin: 16px 0 35px 0;
  overflow-x: scroll;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    display: none;
    width: 0;
    background: transparent;
  }

  ${media.minMobile`
    overflow-x: hidden;  
    ${flexColumnCenterVertically};
    margin: 0 10px;
    height: 100%; 
  `};
`;

const Skeleton = styled(_Skeleton)<{height?: number; width?: number}>``;

const CenteredSkeleton = styled(_Skeleton)<{height: number; width: number}>`
  ${media.minLargeTablet`
    margin: 0 auto;
  `}
`;

const Root = styled.div`
  ${flexColumn};
  ${media.minMobile`  
    overflow-y: scroll;
    flex-direction: row;
    max-height: 550px;
    margin-top: 15px;
  `};
  margin: auto;
  background-color: ${({theme}) => theme.colors.darkBackground};
`;

const CouponLoader = () => (
  <CouponsList>
    <CouponsTitle>
      <CenteredSkeleton width={96} height={24} />
    </CouponsTitle>
    <CouponsWrapper>
      <Skeleton width={168} height={270} />
    </CouponsWrapper>
  </CouponsList>
);

const LoaderComponent = () => (
  <>
    <CouponLoader />
    <CouponLoader />
    <CouponLoader />
  </>
);

type CouponsProps = {
  couponsList?: RestaurantDiscountCoupon[] | RestaurantCouponHappyHour[];
  type: CouponsConfigKey;
};

const Coupons = ({couponsList, type}: CouponsProps) => {
  const {t} = getLocalizationService();

  return (
    <>
      {couponsList && couponsList.length > 0 && (
        <CouponsList>
          <CouponsTitle>{t(COUPONS_CONFIG[type]?.title)}</CouponsTitle>
          <CouponsWrapper>
            {couponsList.map((coupon, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Coupon key={`${type}_${index}`} coupon={coupon} type={type} />
            ))}
          </CouponsWrapper>
        </CouponsList>
      )}
    </>
  );
};

const CouponsComponents = () => {
  const couponsData = useSelector(selectCurrentRestaurantCoupons);

  const isCouponsLoading = useSelector(selectCurrentRestaurantCouponsLoading);
  const isCouponsLoaded = useSelector(selectCurrentRestaurantCouponsLoaded);

  const deliveryCouponList = couponsData?.discountCoupons?.filter(c => c.isCouponActiveNow && c.isOpenForDelivery);
  const pickupCouponList = couponsData?.discountCoupons?.filter(c => c.isCouponActiveNow && c.isOpenForPickup);
  const couponsList: {list?: RestaurantDiscountCoupon[] | RestaurantCouponHappyHour[]; type: CouponsConfigKey}[] = [
    {list: deliveryCouponList, type: 'delivery'},
    {list: pickupCouponList, type: 'pickup'},
    {list: couponsData?.happyHours, type: 'sitting'},
  ];

  return (
    <Root>
      <SkeletonLoader shouldShowLoader={!isCouponsLoaded || isCouponsLoading} LoaderComponent={LoaderComponent}>
        {couponsList.map(({list, type}) => (
          <Coupons key={type} couponsList={list} type={type} />
        ))}
      </SkeletonLoader>
    </Root>
  );
};

export default CouponsComponents;
