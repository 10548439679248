/* eslint-disable quote-props */

import {onFCP, onCLS, onFID, onLCP, onTTFB, Metric} from 'web-vitals';

import {createLogger} from '~/shared/logging';
import Mutex from '~/shared/utils/mutex';
import {getCurrentLocation} from '~/shared/router';
import {
  selectFilteredRestaurants,
  selectActiveFilters,
  selectCurrentDeliveryMethod,
  selectFilters,
} from '~/shared/store/selectors';
import store from '~/shared/store';
import {Cuisine, OrderData, User} from '~/shared/store/models';
import {RestaurantsGroupTypes, STORE_BY_BUSINESS_TYPE} from '~/shared/consts/restaurantConsts';
import {getRestaurantOpenStatusForBI} from '~/shared/utils/restaurants/restaurantStatus';

import {
  AnalyticsDataProps,
  CurrentProduct,
  EventClickedSearchResultProps,
  EventDataType,
  EventLoginProps,
  EventOrderSuccessProps,
  EventSelectResProps,
  EventDeleteAccountProps,
  FilterTypeKey,
  filterTypesMapForAnalyticsNoPrivacy,
  TrackPageviewProps,
  TrackTealiumEventProps,
  EventHasClickedOfficeSupplyFilterProps,
  ActiveOrderRecivedProps,
  ActiveOrderOnClickProps,
  TenBisCreditEventData,
  TenBisCreditEventTypes,
  FormErrorMessageProps,
  ViewedCarouselProps,
  HasClickedShowAllCarouselProps,
  GeneralErrorModalEventNames,
  GeneralErrorModalClickOptions, GoogleAnalyticsEvents,
  NlxOrderTypeSelectProps,
} from './analyticsModels';

const logger = createLogger('analytics');

// "unspecified" is used when we can't log data due to privacy protection issues
const FILTER_VALUE_UNSPECIFIED = 'unspecified';

// use this if you want to portray what the user has chosen.
// some fields will be "unspecified" due to privacy protection issues
const filterTypesMapForAnalyticsWithPrivacy = {
  ...filterTypesMapForAnalyticsNoPrivacy,
  isGlutenFree: FILTER_VALUE_UNSPECIFIED,
  isKosher: FILTER_VALUE_UNSPECIFIED,
  isNotKosher: FILTER_VALUE_UNSPECIFIED,
};

export const isFilterExists = ({analyticsValues}: {analyticsValues: Record<string, any>}) => {
  return Object.values(analyticsValues || {}).some(filter => filter && filter !== 'unspecified');
};

const commonAnalyticsData: AnalyticsDataProps = {
  userData: null,
  userType: null,
  currentAddressCityName: null,
};

let reportedNoFBQ = false;
export const FBQ = (...args: unknown[]) => {
  if (window.fbq) {
    window.fbq(...args);
  } else if (!reportedNoFBQ) {
    // eslint-disable-next-line no-console
    console.warn('Theres no facebook FBQ on the page.');
    reportedNoFBQ = true;
  }
};

export function getUserTypeFromUserData(userData: User) {
  return userData.addresses.some(address => address.isCompanyAddress) ? 'b2b' : 'b2c';
}

export function getLoggedInType(userDetails: User) {
  return `login_${getUserTypeFromUserData(userDetails)}`;
}

const getCurrentProductsForGA = (data: CurrentProduct[]) => data.map(({
  productID,
  productName,
  productPrice,
  productCategory,
  productQuantity,
  productVariant,
}) => ({
  item_id: productID,
  item_name: productName,
  item_variant: productVariant,
  item_category: productCategory,
  price: productPrice,
  quantity: productQuantity,
}));

export const trackTealiumEvent = ({
  key = 'tealium',
  name,
  eventData = {},
  pageData = {},
  restaurantData = {},
  currentProducts = [],
  // GA modified data overrides the default event data in GA format
  gaModifiedData = null,
}: TrackTealiumEventProps) => {
  // If GA4 is still not loaded its safe to create a regular array and add events in it
  // When GA4 is loaded it will handle the added events
  window.dataLayer = window.dataLayer || [];

  const pageDataObj = {
    ...pageData,
    isLoggedIn: !!commonAnalyticsData?.userData,
    LoggedInType: pageData.LoggedInType || commonAnalyticsData?.userType,
  };

  const userDataObj = {
    companyId: commonAnalyticsData?.userData?.companyId,
    crossPlatformCustomerId: commonAnalyticsData?.userData?.crossPlatformCustomerId,
  };

  const ga4EventDataObject = {
    event: name,
    ...(gaModifiedData || eventData),
    pageData: pageDataObj,
    userData: userDataObj,
    restaurantData,
    utag_data: {
      tealium_event: 'custom_dev_none',
    },
  };

  // @ts-expect-error Mutex needs to be rewritten as ts
  Mutex.acquire({key, name}, () => {
    // Track event in tealium
    window.tmsLoaded?.then(() => {
      window.tmsController.data.eventData = {
        ...window.tmsController.data.eventData,
        ...eventData,
      };
      window.tmsController.data.pageData = {
        ...window.tmsController.data.pageData,
        ...pageDataObj,
      };
      window.tmsController.data.userData = {
        ...window.tmsController.data.userData,
        ...userDataObj,
      };
      window.tmsController.data.restaurantData = {
        ...restaurantData,
      };
      window.tmsController.data.currentProducts = currentProducts; // invoking trackEvent() clears this object.
      window.tmsController.trackEvent(name);
    });

    // Track event in GA4
    window.dataLayer.push(ga4EventDataObject);
  });
};
export const convertFilterForAnalytics = (activeFilters: ReturnType<typeof selectFilters>) => {
  const {lastFilterTypeSelected} = activeFilters;
  const activeCuisineId =
    activeFilters.cuisines &&
    Object.keys(activeFilters.cuisines).find(cuisineId => !!activeFilters.cuisines?.[cuisineId]);

  const lastFilterTypeSelectedKey =
    filterTypesMapForAnalyticsNoPrivacy[lastFilterTypeSelected as FilterTypeKey] || lastFilterTypeSelected;

  const analyticsValues = {
    kitchenType: activeCuisineId || 0,
    deliveryCost: Number(activeFilters.freeDelivery || 0),
    freeDeliveryWithTracking: Number(activeFilters.isScoober || 0),
    new: Number(activeFilters.newRestaurants || 0),
    discount: Number(activeFilters.discountCoupon || 0),
    glutenFree: FILTER_VALUE_UNSPECIFIED, // for glutenFree & kosher, always use unspecified due to privacy protection issues
    vegan: Number(activeFilters.isVegan || 0),
    filterEcoFriendly: Number(activeFilters.isEnvironmentFriendly || 0),
    isKosher: FILTER_VALUE_UNSPECIFIED, // for glutenFree & kosher, always use unspecified due to privacy protection issues
    isNotKosher: FILTER_VALUE_UNSPECIFIED, // for glutenFree & kosher, always use unspecified due to privacy protection issues
  };

  const filterAsString = `
    kitchenType=${analyticsValues.kitchenType}&
    deliveryCost=${analyticsValues.deliveryCost}&
    freeDeliveryWithTracking=${analyticsValues.freeDeliveryWithTracking}&
    new=${analyticsValues.new}&
    discount=${analyticsValues.discount}&
    glutenFree=${analyticsValues.glutenFree}& 
    vegan=${analyticsValues.vegan}&
    filterEcoFriendly=${analyticsValues.filterEcoFriendly}&
    isNotKosher=${analyticsValues.isNotKosher}&
    isKosher=${analyticsValues.isKosher}`.replace(/\s+/g, '');

  return {
    lastFilterTypeSelectedKey,
    analyticsValues,
    filterAsString,
  };
};

const eventHandlers = {
  register({userData}: {userData: User}) {
    const userType = 'b2c';
    const LoggedInType = getLoggedInType(userData);

    Object.assign(commonAnalyticsData, {
      userData,
      userType,
    });

    trackTealiumEvent({
      name: 'hasSuccessfullyRegistered',
      eventData: {
        userType,
      },
      pageData: {
        LoggedInType,
      },
    });
  },

  login({userData, isAutomaticLogin, currentAddressCityName}: EventLoginProps) {
    Object.assign(commonAnalyticsData, {
      userData,
      userType: getUserTypeFromUserData(userData),
      currentAddressCityName,
    });

    if (!isAutomaticLogin) {
      const LoggedInType = getLoggedInType(userData);
      trackTealiumEvent({
        name: 'hasSuccessfullyLoggedIn',
        pageData: {
          LoggedInType,
        },
      });
    }
  },

  changeAddress({currentAddressCityName}: {currentAddressCityName: string}) {
    Object.assign(commonAnalyticsData, {
      currentAddressCityName,
    });
  },

  orderSuccess({
    addressCompanyId,
    orderSuccessData,
    userData,
    isFirstTimePrivateOrder,
    orderTotal,
  }: EventOrderSuccessProps) {
    const isPrivateOrder = addressCompanyId === 0;
    const facebookPurchaseType = isPrivateOrder ? 'Private purchase' : 'Corporate purchase';

    const OrderRestaurantId = orderSuccessData?.restaurant?.restaurantId;
    const UserType = getUserTypeFromUserData(userData);

    if (!orderSuccessData?.dishToPresent) {
      logger.warn('No dishToSubmitList in orderSuccessData');
    }

    FBQ('track', facebookPurchaseType, {
      content_type: 'product_group',
      content_ids: [OrderRestaurantId],
      value: orderTotal,
      currency: 'ILS',
    });

    if (isFirstTimePrivateOrder) {
      FBQ('trackCustom', 'FirstTimePrivateOrder', {UserType});
    }
  },

  hasClickedPayWithPaypal() {
    trackTealiumEvent({
      name: 'hasClickedPayWithPaypal',
      eventData: {
        screenType: 'Payment',
      },
    });
  },

  hasSelectedPaymentMethod() {
    trackTealiumEvent({
      name: 'hasSelectedPaymentMethod',
    });
  },

  hasAddedPaymentOption() {
    trackTealiumEvent({
      name: 'hasAddedPaymentOption',
    });
  },

  hasClickedAddPaymentOption() {
    trackTealiumEvent({
      name: 'hasClickedAddPaymentOption',
    });
  },

  hasClickedAddCreditCard({linkType}: {linkType: string}) {
    trackTealiumEvent({
      name: 'hasClickedAddCreditCard',
      eventData: {
        screenType: 'Checkout',
        linkType,
      },
    });
  },

  hasClickedAddPaymentMethod() {
    trackTealiumEvent({
      name: 'hasClickedAddPaymentMethod',
    });
  },

  hasApprovedCreditCard() {
    trackTealiumEvent({
      name: 'hasApprovedCreditCard',
    });
  },

  hasSubmittedVoucher() {
    trackTealiumEvent({
      name: 'hasSubmittedVoucher',
    });
  },

  hasSubmittedRegister() {
    trackTealiumEvent({
      name: 'hasSubmittedRegister',
    });
  },

  hasClickedFacebookLogin_Login() {
    trackTealiumEvent({
      name: 'hasClickedFacebookLogin',
      eventData: {
        linkType: 'login form',
      },
    });
  },

  hasClickedFacebookLogin_Signup() {
    trackTealiumEvent({
      name: 'hasClickedFacebookLogin',
      eventData: {
        linkType: 'register',
      },
    });
  },

  hasSuccessfullyAddedCreditCard() {
    trackTealiumEvent({
      name: 'hasSuccessfullyAddedCreditCard',
      eventData: {
        linkType: 'Payments Modal',
      },
    });
  },

  hasSubmittedOrder(eventData: EventDataType) {
    trackTealiumEvent({
      name: 'hasSubmittedOrder',
      eventData,
    });
  },

  hasClickedIHaveAnAccount() {
    trackTealiumEvent({
      name: 'hasClickedIHaveAnAccount',
      eventData: {
        linkType: 'register form',
      },
    });
  },

  hasClickedCreateAccount() {
    trackTealiumEvent({
      name: 'hasClickedCreateAccount',
      eventData: {
        linkType: 'login form',
      },
    });
  },

  hasFormError(eventData: EventDataType) {
    trackTealiumEvent({
      name: 'hasFormError',
      eventData,
    });
  },

  hasSubmittedFacebookLogin() {
    trackTealiumEvent({
      name: 'hasSubmittedFacebookLogin',
    });
  },

  hasSortedRestaurants(sortOption: string) {
    trackTealiumEvent({
      name: 'hasSortedRestaurants',
      eventData: {
        sortType: sortOption,
      },
    });
  },

  hasSelectedRestaurant({
    restaurantData: eventRestaurantData,
    secondOrderedByConfigUsed,
    groupId,
    itemIndexInGroup,
  }: EventSelectResProps) {
    const state = store.getState();
    const activeFilters = selectActiveFilters(state);
    const deliveryMethod = selectCurrentDeliveryMethod(state);

    const isRestaurantPreOrder = Number(
      groupId === RestaurantsGroupTypes.CLOSED_WITH_FUTURE_ORDER_STARTING_TOMORROW ||
      (eventRestaurantData.isOpenNow && eventRestaurantData.futureOrdersAvailable),
    );

    const {lastFilterTypeSelectedKey, filterAsString} = convertFilterForAnalytics(activeFilters);

    if (!eventRestaurantData) {
      logger.error('restaurant not found in hasSelectedRestaurant');
      return;
    }

    const {restaurantId: restaurantID, restaurantName, isScoober: isScooberRestaurant} = eventRestaurantData;

    const restaurantOpeningStatus = getRestaurantOpenStatusForBI(eventRestaurantData.isOpenNow, {
      tempClosedReason: eventRestaurantData.tempClosedReason,
    });

    const eventData = {
      restaurantDeliveryTime: eventRestaurantData.deliveryTimeInMinutes, // expected delivery time in minutes
      restaurantFreeDelivery: eventRestaurantData.deliveryFee <= 0,
      restaurantMinimumPriceForOrder: eventRestaurantData.minimumPriceForOrder,
      restaurantNumOfReviews: eventRestaurantData.numOfReviews,
      restaurantOpeningStatus,
      restaurantRating: eventRestaurantData.reviewsRankDecimal,
      deliveryMethod,
      filterAll: filterAsString,
      lastFilterTypeSelectedKey,
      restaurantID,
      restaurantName,
      isScooberRestaurant,
      restaurantListOrderBy: secondOrderedByConfigUsed,
      isRestaurantPreOrder,
      cuisineId: eventRestaurantData.restaurantCuisineKeysList && eventRestaurantData.restaurantCuisineKeysList[0],
    };

    const restaurantData = {
      restaurantDeliveryTime: eventRestaurantData.deliveryTimeInMinutes, // expected delivery time in minutes
      restaurantRating: eventRestaurantData.reviewsRankDecimal,
      restaurantMinimumPriceForOrder: eventRestaurantData.minimumPriceForOrder,
      restaurantNumOfReviews: eventRestaurantData.numOfReviews,
      restaurantFreeDelivery: eventRestaurantData.deliveryFee <= 0,
      deliveryMethod,
      isScooberRestaurant,
      restaurantOpeningStatus,
    };

    const currentProducts = [
      {
        productID: restaurantID,
        productName: eventRestaurantData.restaurantName,
        // starting from 1, the position in productList and not on the whole page
        productPosition: itemIndexInGroup + 1,
        // these are actual lists; for now we can use what we have,
        // if in the future we update it we can update it here as well
        // indicates the group of the item: open/closed/pooled order
        productList: groupId,
      },
    ];

    trackTealiumEvent({
      name: 'hasSelectedRestaurant',
      eventData,
      restaurantData,
      currentProducts,
      gaModifiedData: {
        ...eventData,
        resIndex: itemIndexInGroup + 1,
        groupId,
      },
    });
  },

  hasOrderedFromFilterResult({orderData}: {orderData: OrderData}) {
    const state = store.getState();
    const activeFilters = selectActiveFilters(state);

    const {lastFilterTypeSelectedKey, analyticsValues, filterAsString} = convertFilterForAnalytics(activeFilters);

    if (!isFilterExists({analyticsValues})) {
      return;
    }

    if (!lastFilterTypeSelectedKey) {
      return;
    }

    const transactionPaymentCost = orderData.shoppingCart.billingLines.find(
      line => line.type === 'TotalToCharge',
    )?.amount;

    const eventData = {
      lastFilterTypeSelectedKey,
      MadeTransactionFilterAll: filterAsString,
      transactionPaymentCost,
      restaurantId: orderData.restaurant.restaurantId,
      TransactionID: orderData.orderId,
    };

    // delete eventData.filterAsString;

    trackTealiumEvent({
      name: 'hasOrderedFromFilterResult',
      eventData,
    });
  },

  sendFilterDataLayer({filterType, isChecked}: {filterType: string; isChecked: boolean}) {
    if (!filterType || !store) {
      logger.warn('sendFilterDataLayer - filterType and store are required');
      return;
    }

    const state = store.getState();
    const filteredRestaurantsWithGroup = selectFilteredRestaurants(state);
    const filteredRestaurantsPresentedNumber = [...filteredRestaurantsWithGroup.filter(item => !item.isGroup)].length;
    const activeFilters = selectActiveFilters(state);

    if (isChecked) {
      const {lastFilterTypeSelectedKey, analyticsValues, filterAsString} = convertFilterForAnalytics(activeFilters);

      const dataLayerForFilteredRestaurants = {
        lastFilterTypeSelectedKey,
        countRestaurantShown: filteredRestaurantsPresentedNumber,
        filterKitchenType: analyticsValues.kitchenType,
        filterDeliveryCost: analyticsValues.deliveryCost,
        filterNew: analyticsValues.new,
        filterDiscount: analyticsValues.discount,
        filterKosher: analyticsValues.isKosher,
        filterVegan: analyticsValues.vegan,
        filterGlutenFree: analyticsValues.glutenFree,
        filterEcoFriendly: analyticsValues.filterEcoFriendly,
        filterFreeDeliveryWithTracking: analyticsValues.freeDeliveryWithTracking,
        filterAll: filterAsString,
      };

      trackTealiumEvent({name: 'hasFilteredRestaurants', eventData: dataLayerForFilteredRestaurants});
    } else {
      const dataLayerForUnfilteredRestaurants = {
        filterType: filterTypesMapForAnalyticsWithPrivacy[filterType as FilterTypeKey] || filterType,
        countRestaurantShown: filteredRestaurantsPresentedNumber,
      };
      trackTealiumEvent({name: 'hasUnfilteredRestaurants', eventData: dataLayerForUnfilteredRestaurants});
    }
  },

  hasViewedLoginStep(eventData: EventDataType) {
    trackTealiumEvent({
      name: 'hasViewedLoginStep',
      eventData,
    });
  },

  hasClickedLogin(eventData: EventDataType) {
    trackTealiumEvent({
      name: 'hasClickedLogin',
      eventData,
    });
  },

  hasClickedSendCodeAgain(eventData: EventDataType) {
    trackTealiumEvent({
      name: 'hasClickedSendCodeAgain',
      eventData,
    });
  },

  hasClickedDidntReceiveCode(eventData: EventDataType) {
    trackTealiumEvent({
      name: 'hasClickedDidntReceiveCode',
      eventData,
    });
  },

  hasClickedForgotPassword(eventData: EventDataType) {
    trackTealiumEvent({
      name: 'hasClickedForgotPassword',
      eventData,
    });
  },

  hasChangedMarketingBannerManually() {
    trackTealiumEvent({
      name: 'hasChangedMarketingBannerManually',
    });
  },

  hasClickedMarketingBanner(eventData: EventDataType) {
    trackTealiumEvent({
      name: 'hasClickedMarketingBanner',
      eventData,
    });
  },

  hasStartedSearching() {
    trackTealiumEvent({
      name: 'hasStartedSearching',
    });
  },

  hasOpenedSearchComponent() {
    trackTealiumEvent({
      name: 'hasOpenedSearchComponent',
    });
  },

  hasExitedSearchComponent({searchString, countResultsShown}: {searchString: string; countResultsShown: number}) {
    trackTealiumEvent({
      name: 'hasExitedSearchComponent',
      eventData: {
        searchString,
        countResultsShown,
      },
    });
  },

  hasClickedSearchResult({
    searchString,
    countResultsShown,
    selectedItemType,
    selectedItemName,
    selectedItemID,
    selectedItemPositionResultsList,
    selectedItemPositionTypeList,
    searchListValue,
  }: EventClickedSearchResultProps) {
    trackTealiumEvent({
      name: 'hasClickedSearchResult',
      currentProducts: [
        {
          productID: selectedItemID,
          productName: selectedItemName,
          productList: 'Search Results',
          productCategory: selectedItemType,
          productPosition: selectedItemPositionResultsList,
        },
      ],
      eventData: {
        productSubPosition: selectedItemPositionTypeList,
        searchString,
        countResultsShown,
        searchListValue,
      },
      gaModifiedData: {
        selectedItemID,
        selectedItemName,
        selectedItemType,
        selectedItemPositionResultsList,
        productSubPosition: selectedItemPositionTypeList,
        searchString,
        countResultsShown,
        searchListValue,
      },
    });
  },

  hasOpenedReorderDropdown() {
    trackTealiumEvent({
      name: 'hasOpenedReorderDropdown',
    });
  },

  hasClickedReorder(eventData: EventDataType) {
    trackTealiumEvent({
      name: 'hasClickedReorder',
      eventData,
    });
  },

  hasReorderPopupUnavailable(eventData: EventDataType) {
    trackTealiumEvent({
      name: 'hasReorderPopupUnavailable',
      eventData,
    });
  },

  hasViewedCart(eventData: EventDataType) {
    trackTealiumEvent({
      name: 'view_cart',
      eventData,
    });
  },

  hasReorderPopupAllUnavailable() {
    trackTealiumEvent({
      name: 'hasReorderPopupAllUnavailable',
    });
  },

  hasClickedViewOrder(eventData: EventDataType) {
    trackTealiumEvent({
      name: 'hasClickedViewOrder',
      eventData,
    });
  },

  hasClickedDeleteAccount(eventData: EventDeleteAccountProps) {
    trackTealiumEvent({
      name: 'hasClickedDeleteAccount',
      eventData,
    });
  },

  hasContinueDeleteAccount(eventData: EventDeleteAccountProps) {
    trackTealiumEvent({
      name: 'hasContinueDeleteAccount',
      eventData,
    });
  },

  hasApproveDeleteAccount(eventData: EventDeleteAccountProps) {
    trackTealiumEvent({
      name: 'hasApproveDeleteAccount',
      eventData,
    });
  },

  hasSuccessfullyDeleteAccount(eventData: EventDeleteAccountProps) {
    trackTealiumEvent({
      name: 'hasSuccessfullyDeleteAccount',
      eventData,
    });
  },

  hasFailedDeleteAccount(eventData: EventDeleteAccountProps) {
    trackTealiumEvent({
      name: 'hasFailedDeleteAccount',
      eventData,
    });
  },

  hasViewedProductDetail(dishData: CurrentProduct) {
    trackTealiumEvent({
      name: 'hasViewedProductDetail',
      currentProducts: [
        {
          productID: dishData.productID,
          productName: dishData.productName,
          productPrice: dishData.productPrice,
          productCategory: dishData.productCategory,
          productVariant: dishData.productVariant,
        },
      ],
      gaModifiedData: {
        event: GoogleAnalyticsEvents.VIEW_ITEM,
        ecommerce: {
          items: getCurrentProductsForGA([dishData]),
        },
      },
    });
  },

  hasAddedToCart(dishData: CurrentProduct) {
    trackTealiumEvent({
      name: 'hasAddedToCart',
      currentProducts: [
        {
          productID: dishData.productID,
          productName: dishData.productName,
          productPrice: dishData.productPrice,
          productCategory: dishData.productCategory,
          productQuantity: dishData.productQuantity,
          productVariant: dishData.productVariant,
        },
      ],
      gaModifiedData: {
        event: GoogleAnalyticsEvents.ADD_TO_CART,
        ecommerce: {
          items: getCurrentProductsForGA([dishData]),
        },
      },
    });
  },

  hasRemovedFromCart(dishData: CurrentProduct) {
    trackTealiumEvent({
      name: 'hasRemovedFromCart',
      currentProducts: [
        {
          productID: dishData.productID,
          productName: dishData.productName,
          productPrice: dishData.productPrice,
          productCategory: dishData.productCategory,
          productQuantity: dishData.productQuantity,
          productVariant: dishData.productVariant,
        },
      ],
      gaModifiedData: {
        event: GoogleAnalyticsEvents.REMOVE_FROM_CART,
        ecommerce: {
          items: getCurrentProductsForGA([dishData]),
        },
      },
    });
  },

  hasViewedMinimumOrderPopup() {
    trackTealiumEvent({
      name: 'hasViewedMinimumOrderPopup',
    });
  },

  hasReachedMOV() {
    trackTealiumEvent({
      name: 'hasReachedMOV',
    });
  },

  hasResetShoopingCart() {
    trackTealiumEvent({
      name: 'hasResetShoopingCart',
    });
  },

  hasBeginCheckout(currentProducts: CurrentProduct[]) {
    trackTealiumEvent({
      name: 'hasBeginCheckout',
      currentProducts,
      gaModifiedData: {
        event: GoogleAnalyticsEvents.BEGIN_CHECKOUT,
        ecommerce: {
          items: getCurrentProductsForGA(currentProducts),
        },
      },
    });
  },

  hasCancelledOrder(eventData: EventDataType) {
    trackTealiumEvent({
      name: 'hasCancelledOrder',
      eventData,
    });
  },

  hasSelectedChain(eventData: EventDataType) {
    trackTealiumEvent({
      name: 'hasSelectedChain',
      eventData,
    });
  },

  hasViewedAlcoholPopUp() {
    trackTealiumEvent({
      name: 'hasViewedAlcoholPopUp',
    });
  },

  firstBisPopupView() {
    trackTealiumEvent({
      name: 'firstBisPopup_view',
    });
  },

  firstBisPopupClick(eventData: EventDataType) {
    trackTealiumEvent({
      name: 'firstBisPopup_click',
      eventData,
    });
  },

  hasClickedAlcoholPopUp(eventData: EventDataType) {
    trackTealiumEvent({
      name: 'hasClickedAlcoholPopUp',
      eventData,
    });
  },

  hasChangedDateInBillingReport(eventData: {dateSelected: string}) {
    trackTealiumEvent({
      name: 'hasChangedDateInBillingReport',
      eventData,
    });
  },
  hasClickedAddressDropdown() {
    trackTealiumEvent({
      name: 'hasClickedAddressDropdown',
    });
  },

  hasChangedLocation({addressType}: {addressType: string}) {
    trackTealiumEvent({
      name: 'hasChangedLocation',
      eventData: {
        addressType,
      },
    });
  },

  addressStartSearch({searchOption}: {searchOption: 'typing' | 'location_based'}) {
    trackTealiumEvent({
      name: 'address_startSearch',
      eventData: {
        searchOption,
      },
    });
  },

  hasModifiedAddress({
    modifiedAddressType, modifiedLocation,
  }: {
    modifiedAddressType: 'add address' | 'remove address' | 'edit address'; modifiedLocation: 'homepage' | 'address dropdown';
  }) {
    trackTealiumEvent({
      name: 'hasModifiedAddress',
      eventData: {
        modifiedAddressType,
        modifiedLocation,
      },
    });
  },

  hasSwitchedDeliveryType({deliveryMethod}: {deliveryMethod: string}) {
    trackTealiumEvent({
      name: 'hasSwitchedDeliveryType',
      eventData: {
        deliveryMethod,
      },
    });
  },

  hasRecivedActiveOrderWidget(eventData: ActiveOrderRecivedProps) {
    trackTealiumEvent({
      name: 'hasRecivedActiveOrderWidget',
      eventData,
    });
  },

  hasRecivedCancelledOrderWidget(eventData: ActiveOrderRecivedProps) {
    trackTealiumEvent({
      name: 'hasRecivedCancelledOrderWidget',
      eventData,
    });
  },

  hasClickedOrderWidget(eventData: ActiveOrderOnClickProps) {
    trackTealiumEvent({
      name: 'hasClickedOrderWidget',
      eventData,
    });
  },

  hasCheckedOutDiscountCampus({dinningRoomNoPackingRequired}: {dinningRoomNoPackingRequired: boolean}) {
    trackTealiumEvent({
      name: 'hasCheckedOutDiscountCampus',
      eventData: {
        discountCheckedOption: dinningRoomNoPackingRequired ? 'sit' : 'takeaway',
      },
    });
  },

  hasStartedMenuSearch() {
    trackTealiumEvent({
      name: 'hasStartedMenuSearch',
    });
  },

  hasDeletedMenuSearch() {
    trackTealiumEvent({
      name: 'hasDeletedMenuSearch',
    });
  },

  hasFilteredKitchenType(eventData: EventDataType) {
    trackTealiumEvent({
      name: 'hasFilteredKitchenType',
      eventData,
    });
  },

  hasUnFilteredKitchenType(eventData: EventDataType) {
    trackTealiumEvent({
      name: 'hasUnFilteredKitchenType',
      eventData,
    });
  },

  hasViewedOfficeSupplyFilter() {
    trackTealiumEvent({
      name: 'hasViewedOfficeSupplyFilter',
    });
  },

  hasOpenedOfficeSupplyFilter() {
    trackTealiumEvent({
      name: 'hasOpenedOfficeSupplyFilter',
    });
  },

  hasClickedOfficeSupplyFilter(eventData: EventHasClickedOfficeSupplyFilterProps) {
    trackTealiumEvent({
      name: 'hasClickedOfficeSupplyFilter',
      eventData,
    });
  },

  hasResetOfficeSupplyFilter() {
    trackTealiumEvent({
      name: 'hasResetOfficeSupplyFilter',
    });
  },

  hasFutureOrderDishUnavailable(eventData: EventDataType) {
    trackTealiumEvent({
      name: 'hasFutureOrderDishUnavailable',
      eventData,
    });
  },
  hasViewedRegistrationPopup() {
    trackTealiumEvent({
      name: 'hasViewedRegistrationPopup',
    });
  },
  hasOpenAccessibilityMenu() {
    trackTealiumEvent({
      name: 'hasOpenAccessibilityMenu',
    });
  },

  hasClosedAccessibilityMenu() {
    trackTealiumEvent({
      name: 'hasClosedAccessibilityMenu',
    });
  },

  hasClickedAccessibilityMenu(eventData: EventDataType) {
    trackTealiumEvent({
      name: 'hasClickedAccessibilityMenu',
      eventData,
    });
  },

  formErrorMessageView(eventData: FormErrorMessageProps) {
    trackTealiumEvent({
      name: 'formErrorMessage_view',
      eventData,
    });
  },

  hasViewedCarousel(eventData: ViewedCarouselProps) {
    trackTealiumEvent({
      name: 'hasViewedCarousel',
      eventData,
    });
  },

  hasClickedShowAllCarousel(eventData: HasClickedShowAllCarouselProps) {
    trackTealiumEvent({
      name: 'hasClickedShowAllCarousel',
      eventData,
    });
  },

  hasClickedCarousel(eventData: ViewedCarouselProps) {
    trackTealiumEvent({
      name: 'hasClickedCarousel',
      eventData,
    });
  },

  hasSwipeCarousel(eventData: ViewedCarouselProps) {
    trackTealiumEvent({
      name: 'hasSwipeCarousel',
      eventData,
    });
  },

  abTestParticipated({experimentId, variant}: {experimentId: string; variant: string}) {
    trackTealiumEvent({
      name: 'abTestParticipated',
      eventData: {
        experimentId,
        variant,
      },
    });
  },

  nlxOrderType_click(eventData: {original_orderType: string; pageSource: string}) {
    trackTealiumEvent({
      name: 'nlxOrderType_click',
      eventData,
    });
  },

  nlxOrderType_select(eventData: NlxOrderTypeSelectProps) {
    trackTealiumEvent({
      name: 'nlxOrderType_select',
      eventData,
    });
  },

  // #region 10biscredit
  handleTenBisCredit({type, eventData, errorType}: {type: TenBisCreditEventTypes; eventData?: TenBisCreditEventData; errorType?: string}) {
    const eventName = `transferBalance_${type}`;

    const voidEvents = [
      TenBisCreditEventTypes.CLICK,
      TenBisCreditEventTypes.READ_MORE,
    ];

    if (voidEvents.includes(type)) {
      trackTealiumEvent({name: eventName});
      return;
    }

    trackTealiumEvent({
      name: eventName,
      eventData: {
        available_balance: eventData?.availableBalance,
        transfer_balance: eventData?.transferBalance,
        errorType,
      },
    });
  },
  // #endregion

  handleCloseRestaurantModal({eventName, resId, clickOptions}: {eventName: GeneralErrorModalEventNames; resId: string; clickOptions?: GeneralErrorModalClickOptions}) {
    const eventData: Record<string, string | number> = {
      restaurantID: Number(resId),
    };

    if (clickOptions) {
      eventData.click_options = clickOptions;
    }

    trackTealiumEvent({
      name: eventName,
      eventData,
    });

  },

  categoryRibbon_click({cuisineId, position}: {cuisineId: Cuisine['id'] | typeof STORE_BY_BUSINESS_TYPE; position: number}) {
    trackTealiumEvent({
      name: 'categoryRibbon_click',
      eventData: {
        category_ribbon_name: cuisineId,
        category_ribbon_position: position,
      },
    });
  },
  
  multipassFetchFailed({orderId}: {orderId: OrderData['orderId']}) {
    trackTealiumEvent({
      name: 'multiPassFailedLoad_view',
      eventData: {orderId},
    });
  },

  multipassFetchRetryClick({orderId}: {orderId: OrderData['orderId']}) {
    trackTealiumEvent({
      name: 'multiPassFailedLoad_tryAgainClick',
      eventData: {orderId},
    });
  },
};

export function trackEvent(eventName: keyof typeof eventHandlers, payload?: any) {
  const handler = eventHandlers[eventName];

  if (!handler) {
    logger.warn(`a handler was not found for the event ${eventName}`);
  }

  try {
    handler(payload);
    logger.verbose(`${eventName} event handled.`);
  } catch (err) {
    logger.warn('analytics event tracking error', {err});
  }
}

export const trackPageview = ({
  key = 'tealium',
  eventPayload = {},
  currentLanguageKey,
  pageGroup,
}: TrackPageviewProps) => {
  if (!pageGroup || !currentLanguageKey) {
    logger.error('trackPageview received wrong data', {eventPayload, currentLanguageKey, pageGroup});
    return;
  }

  const {internallyRefreshedOnLocation} = getCurrentLocation();
  if (internallyRefreshedOnLocation) {
    // if the page was refreshed internally (like from the refresh modal) we don't want to track the page again
    return;
  }

  const gaPageViewDataObj = {
    event: GoogleAnalyticsEvents.PAGE_VIEW,
    url: document.location.href,
    languageKey: currentLanguageKey,
    pageGroup,
    isLoggedIn: !!commonAnalyticsData?.userData,
    user_data: {
      companyId: commonAnalyticsData?.userData?.companyId,
      crossPlatformCustomerId: commonAnalyticsData?.userData?.crossPlatformCustomerId,
      userCity: commonAnalyticsData?.currentAddressCityName,
      LoggedInType: commonAnalyticsData?.userType || 'none',
    },
    utag_data: {
      tealium_event: 'custom_dev_none',
    },
  };

  const gaMenuViewDataObj = eventPayload?.restaurantData ? {
    event: GoogleAnalyticsEvents.MENU_VIEW,
    ...eventPayload.restaurantData,
    utag_data: {
      tealium_event: 'custom_dev_none',
    },
  } : null;

  const gaPurchaseDataObj = eventPayload?.transactionData && eventPayload.currentProducts ? {
    event: GoogleAnalyticsEvents.PURCHASE,
    ecommerce: {
      ...eventPayload.transactionData,
      // Conversion from Tealium format to GA4 format
      // TODO: - remove this conversion when tealium is removed
      items: getCurrentProductsForGA(eventPayload.currentProducts),
    },
    utag_data: {
      tealium_event: 'custom_dev_none',
    },
  } : null;

  // why do we need mutex here:
  // https://tenbis.visualstudio.com/TenBis/_wiki/wikis/TenBis.wiki/172/Mutex-a-temporary-solution-to-a-tealium-endless-loop-error
  Mutex.acquire({key, name: pageGroup, errOnQueue: true}, () => {
    window.tmsLoaded?.then(() => {
      Object.assign(window.tmsController.data, {
        ...eventPayload,
        pageData: {
          ...window.tmsController.data.pageData,
          url: document.location.href,
          isLoggedIn: !!commonAnalyticsData?.userData,
          languageKey: currentLanguageKey,
          pageGroup,
          LoggedInType: commonAnalyticsData?.userType || 'none',
        },
        userData: {
          ...window.tmsController.data.userData,
          companyId: commonAnalyticsData?.userData?.companyId,
          crossPlatformCustomerId: commonAnalyticsData?.userData?.crossPlatformCustomerId,
          userCity: commonAnalyticsData?.currentAddressCityName,
        },
      });

      logger.verbose(`Track page view for - ${key}`, {
        tmsControllerData: {...window.tmsController.data},
        currentLanguageKey,
        pageGroup,
      });

      window.tmsController.trackPageview();
    });

    // GA events submission
    window.dataLayer.push(gaPageViewDataObj);

    if (gaMenuViewDataObj) {
      window.dataLayer.push(gaMenuViewDataObj);
    }

    if (gaPurchaseDataObj) {
      window.dataLayer.push(gaPurchaseDataObj);
    }
  });
};

// Used in App.js on application mount
export const collectWebVitalsMetrics = () => {
  const reportVital = (metric: Metric) => {
    trackTealiumEvent({
      name: 'web_vitals',
      eventData: {
        vital_name: metric.name,
        vital_value: metric.value,
        vital_delta: metric.delta,
      },
    });
  };

  onTTFB(reportVital);
  onLCP(reportVital);
  onFID(reportVital);
  onCLS(reportVital);
  onFCP(reportVital);
};
