import {lazy, Suspense, memo} from 'react';

import {Switch, Route, addPathnamePrefix} from '~/shared/router';
import {routeNames, routes} from '~/shared/routes';
import ReduxModal from '~/shared/components/ReduxModal';
import PageFooter from '~/shared/components/PageFooter';
import initManager from '~/shared/managers/initManager';
import {FadingLoader} from '~/shared/components/Loaders';

import DeveloperErrorModal from './DeveloperErrorModal';

const ReactLazyPreload = importStatement => {
  const Component = lazy(importStatement);
  Component.preload = importStatement;
  return Component;
};

const routeNameToComponentMap = {
  [routeNames.home]: ReactLazyPreload(() =>
    import(
      /* webpackChunkName: "home-page" */
      /* webpackMode: "lazy" */
      '~/home'
    ),
  ),
  [routeNames.menu]: ReactLazyPreload(() =>
    import(
      /* webpackChunkName: "menu-page" */
      /* webpackMode: "lazy" */
      '~/menu'
    ),
  ),
  [routeNames.restaurants]: ReactLazyPreload(() =>
    import(
      /* webpackChunkName: "restaurants-page" */
      /* webpackMode: "lazy" */
      '~/restaurants'
    ),
  ),
  [routeNames.dish]: ReactLazyPreload(() =>
    import(
      /* webpackChunkName: "general-pages" */
      /* webpackMode: "lazy" */
      '~/dish'
    ),
  ),
  [routeNames.city]: lazy(() =>
    import(
      /* webpackChunkName: "general-pages" */
      /* webpackMode: "lazy" */
      '~/cityPage'
    ),
  ),
  [routeNames.shoppingCart]: lazy(() =>
    import(
      /* webpackChunkName: "general-pages" */
      /* webpackMode: "lazy" */
      '~/shared/components/ShoppingCart/ShoppingCartPage'
    ),
  ),
  [routeNames.checkout]: lazy(() =>
    import(
      /* webpackChunkName: "general-pages" */
      /* webpackMode: "lazy" */
      '~/checkout'
    ),
  ),
  [routeNames.orderSuccess]: lazy(() =>
    import(
      /* webpackChunkName: "general-pages" */
      /* webpackMode: "lazy" */
      '~/orderSuccess'
    ),
  ),
  [routeNames.orderSummary]: lazy(() =>
    import(
      /* webpackChunkName: "general-pages" */
      /* webpackMode: "lazy" */
      '~/orderSummary'
    ),
  ),
  [routeNames.info]: lazy(() =>
    import(
      /* webpackChunkName: "general-pages" */
      /* webpackMode: "lazy" */
      '~/infoPage'
    ),
  ),
  [routeNames.sitemap]: lazy(() =>
    import(
      /* webpackChunkName: "general-pages" */
      /* webpackMode: "lazy" */
      '~/siteMap'
    ),
  ),
  [routeNames.signin]: lazy(() =>
    import(
      /* webpackChunkName: "general-pages" */
      /* webpackMode: "lazy" */
      '~/signin'
    ),
  ),
  [routeNames.updateUserDetails]: lazy(() =>
    import(
      /* webpackChunkName: "general-pages" */
      /* webpackMode: "lazy" */
      '~/updateUserDetails'
    ),
  ),
  [routeNames.linkMoneycard]: lazy(() =>
    import(
      /* webpackChunkName: "general-pages" */
      /* webpackMode: "lazy" */
      '~/linkMoneycard'
    ),
  ),
  [routeNames.restaurantsGroup]: lazy(() =>
    import(
      /* webpackChunkName: "general-pages" */
      /* webpackMode: "lazy" */
      '~/restaurantsGroupPage'
    ),
  ),
  [routeNames.userReport]: lazy(() =>
    import(
      /* webpackChunkName: "general-pages" */
      /* webpackMode: "lazy" */
      '~/userReport'
    ),
  ),
  [routeNames.cancelActiveOrder]: lazy(() =>
    import(
      /* webpackChunkName: "general-pages" */
      /* webpackMode: "lazy" */
      '~/cancelOrderWarning'
    ),
  ),
  [routeNames.coupons]: lazy(() =>
    import(
      /* webpackChunkName: "general-pages" */
      /* webpackMode: "lazy" */
      '~/coupons'
    ),
  ),
  [routeNames.accessibilityStatement]: lazy(() =>
    import(
      /* webpackChunkName: "general-pages" */
      /* webpackMode: "lazy" */
      '~/accessibilityStatement'
    ),
  ),
  [routeNames.otl]: lazy(() =>
    import(
      /* webpackChunkName: "general-pages" */
      /* webpackMode: "lazy" */
      '~/otlPage'
    ),
  ),
  [routeNames.payments]: lazy(() =>
    import(
      /* webpackChunkName: "general-pages" */
      /* webpackMode: "lazy" */
      '~/paymentsPage'
    ),
  ),
  [routeNames.chains]: lazy(() =>
    import(
      /* webpackChunkName: "general-pages" */
      /* webpackMode: "lazy" */
      '~/chainPage'
    ),
  ),
  [routeNames.errorPage]: lazy(() =>
    import(
      /* webpackChunkName: "general-pages" */
      /* webpackMode: "lazy" */
      '~/errorPage'
    ),
  ),
  [routeNames.reorder]: lazy(() =>
    import(
      /* webpackChunkName: "general-pages" */
      /* webpackMode: "lazy" */
      '~/reorder'
    ),
  ),
  [routeNames.deleteAccount]: lazy(() =>
    import(
      /* webpackChunkName: "general-pages" */
      /* webpackMode: "lazy" */
      '~/deleteAccount'
    ),
  ),
  [routeNames.confirmAge]: lazy(() =>
    import(
      /* webpackChunkName: "general-pages" */
      /* webpackMode: "lazy" */
      '~/ageRestriction'
    ),
  ),
  [routeNames.unsubscribe]: lazy(() =>
    import(
      /* webpackChunkName: "general-pages" */
      /* webpackMode: "lazy" */
      '~/unsubscribe'
    ),
  ),
  [routeNames.gfoOptOut]: lazy(() =>
    import(
      /* webpackChunkName: "general-pages" */
      /* webpackMode: "lazy" */
      '~/gfoOptOut'
    ),
  ),
};

initManager.runWhenInitialized(async () => {
  // preload the different routes one after another (async waterfall).
  const cmps = Object.values(routeNameToComponentMap);
  for (let index = 0; index < cmps.length; index++) {
    if (cmps[index].preload) {
      // eslint-disable-next-line no-await-in-loop
      await cmps[index].preload();
    }
  }
});

const Routes = memo(() => {
  return (
    <Suspense fallback={<FadingLoader />}>
      <Switch>
        {routes.map(route => {
          const path = route?.path.map(addPathnamePrefix);
          return (
            <Route {...route} path={path} key={route.name}>
              {() => {
                const RouteComponent = routeNameToComponentMap[route.name];
                return (
                  <>
                    <RouteComponent />
                    <DeveloperErrorModal />
                    <ReduxModal disableEruptModals={route.disableEruptModals} />
                    {!route.hideFooter && <PageFooter />}
                  </>
                );
              }}
            </Route>
          );
        })}
      </Switch>
    </Suspense>
  );
});

export default Routes;
