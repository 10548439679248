import {useEffect} from 'react';

import {useSelector} from 'react-redux';

import {getLocalizationService} from '~/shared/services/localisationService';
import {createLogger} from '~/shared/logging';
import {getCurrentLocation, useRouteParams} from '~/shared/router';
import {trackPageview} from '~/shared/services/analytics';
import initManager from '~/shared/managers/initManager';
import {
  selectCurrentRestaurant,
  selectCurrentRestaurantId,
  selectCurrentRestaurantIsLoading,
} from '~/shared/store/selectors';
import store from '~/shared/store';
import ManagerProvider from '~/shared/managers/ManagerProvider';
import {navigateToDefaultStartPage} from '~/shared/services/navigation';

import RestaurantCoupons from './RestaurantCoupons';

const logger = createLogger('CouponsPage');

const CouponsPage = () => {
  const {restaurantId: restaurantIdFromRoute} = useRouteParams<{restaurantId: number}>();
  const currentRestaurantIsLoading = useSelector(selectCurrentRestaurantIsLoading);

  useEffect(() => {
    logger.log('*** initializing coupon page ***');

    const {currentLanguageKey} = getLocalizationService();
    const {routeParams} = getCurrentLocation();

    trackPageview({
      eventPayload: {
        restaurantData: {restaurantID: routeParams.restaurantId},
      },
      currentLanguageKey,
      pageGroup: 'CouponsPage',
    });
  }, []);

  initManager.useOnPageInit(async () => {
    if (selectCurrentRestaurantId(store.getState())) {
      return;
    }
    await ManagerProvider.changeRestaurant({restaurantId: restaurantIdFromRoute});

    const currentRestaurant = selectCurrentRestaurant(store.getState());
    if (!currentRestaurantIsLoading && !currentRestaurant) {
      navigateToDefaultStartPage();
    }
  });

  return <RestaurantCoupons showHeader={false} />;
};

export default CouponsPage;
