import {memo} from 'react';

import styled from 'styled-components';

import ImageWithAlt from '~/shared/components/ImageWithAlt';
import {PriceLabel} from '~/shared/components/PriceLabels';
import {media, flipOnLTR} from '~/shared/theme/utils';
import {getLocalizationService} from '~/shared/services/localisationService';
import {body13Normal, body20Normal, subheader26Bold} from '~/shared/theme/typography';
import {flexCenterHorizontally, flexColumnCenterVertically} from '~/shared/theme/FlexLayout';
import {useAsyncSanitizeHtml} from '~/shared/utils/sanitizeHtml';
import {RestaurantCouponHappyHour, RestaurantDiscountCoupon} from '~/shared/store/models';

import {COUPONS_CONFIG, CouponsConfigKey} from '../constants';

const CouponBody = styled.div<{color?: string}>`
  ${body20Normal};
  width: 170px;
  min-width: 170px;
  min-height: 270px;
  padding: 15px 0;
  background-color: ${({color}) => color};
  box-shadow: ${({theme}) => theme.shadows.couponShadow};
  color: ${({theme}) => theme.colors.surfacePrimaryActionText};
  border-radius: 7px;

  &::after,
  &::before {
    content: '';
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: ${({theme}) => theme.colors.surface};
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    ${media.minMobile`
      background-color: ${({theme}: any) => theme.modal.backgroundColor};
    `}
  }

  &::before {
    top: -15px;
    bottom: auto;
  }

  &::after {
    bottom: -15px;
    top: auto;
  }

  ${flipOnLTR`
    margin-left: 15px;
    ${media.minMobile`
      margin-bottom: 20px;
      margin-left: 0;
    `};
  `}
`;

const DiscountValue = styled.div`
  ${subheader26Bold};
  line-height: 32px;
  width: 100%;
  text-align: center;
  height: 70px;
  ${flexColumnCenterVertically};
`;

const DottedLine = styled.div`
  border-bottom: 2px dashed ${({theme}) => theme.colors.surface};
  margin-bottom: 15px;
`;

const BackgroundSvg = styled(ImageWithAlt).attrs(({svgSrc}: {svgSrc: string}) => ({
  src: svgSrc,
}))<{svgSrc: string; noAlt: boolean}>`
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -50px 0 0 -50px;
  height: 150px;
  width: 100px;
`;

const CouponText = styled.div`
  ${flipOnLTR`
    text-align: right;
  `}
  ${body13Normal};
  margin: 0 10px 25px 10px;
`;

const CouponTextLine = styled.div`
  display: flex;
`;

const DiscountValueInnerWrapper = styled.div`
  ${flexCenterHorizontally};
`;

const DiscountValueLabel = styled(PriceLabel)`
  ${flipOnLTR`
    margin-left: 3px;
  `}
`;

const DiscountFromValueLabel = styled(PriceLabel)`
  ${flipOnLTR`
    margin-right: 3px;
  `}
`;

type CouponProps = {
  coupon: RestaurantDiscountCoupon | RestaurantCouponHappyHour;
  type: CouponsConfigKey;
};

const isDiscountCoupon = (
  coupon: RestaurantDiscountCoupon | RestaurantCouponHappyHour,
): coupon is RestaurantDiscountCoupon => 'discountValue' in coupon;

const Coupon = ({coupon, type}: Readonly<CouponProps>) => {
  const {validTillStr, dayTimeCouponValidationList} = coupon;
  const discountCoupon = isDiscountCoupon(coupon) ? coupon : null;
  const happyHourCoupon = !isDiscountCoupon(coupon) ? coupon : null;
  const {discountValue, discountValueType, shoppingcartRequiementAmount} = discountCoupon || {};
  const {discountContent} = happyHourCoupon || {};

  const {t} = getLocalizationService();
  const sanitizedDiscountContent = useAsyncSanitizeHtml(discountContent);

  return (
    <CouponBody color={COUPONS_CONFIG[type]?.background}>
      <DiscountValue>
        <DiscountValueInnerWrapper>
          {discountValue && (
            <DiscountValueLabel format={false} price={discountValue} discount={discountValueType === 'Percent'} />
          )}
          {t('discount')}
        </DiscountValueInnerWrapper>
      </DiscountValue>

      <DottedLine />

      <BackgroundSvg svgSrc={COUPONS_CONFIG[type]?.image || ''} noAlt />

      <CouponText>
        {type === 'sitting' && <CouponTextLine>{t('discount_given_automatically_upon_payment')}</CouponTextLine>}

        {!!sanitizedDiscountContent && <CouponTextLine>{sanitizedDiscountContent}</CouponTextLine>}

        <CouponTextLine>{`${t('valid_until')}: ${validTillStr}`}</CouponTextLine>

        {dayTimeCouponValidationList &&
          dayTimeCouponValidationList.map(dayText => <CouponTextLine key={dayText}>{dayText}</CouponTextLine>)}

        {!!shoppingcartRequiementAmount && (
          <CouponTextLine>
            {t('for_orders_from')} <DiscountFromValueLabel format={false} price={shoppingcartRequiementAmount} />
          </CouponTextLine>
        )}
      </CouponText>
    </CouponBody>
  );
};

export default memo(Coupon);
