import {useMemo} from 'react';

import styled from 'styled-components';
import {useSelector} from 'react-redux';

import {
  selectAllCoupons,
  selectAllCouponsLoading,
  selectCurrentCoupon,
  selectIsRestaurantCouponAvailable,
} from '~/shared/store/selectors';
import {SkeletonLoader, Skeleton} from '~/shared/components/Loaders';
import ManagerProvider from '~/shared/managers/ManagerProvider';
import {useIsMounted} from '~/shared/hooks';

import CouponModes from './CouponsModes';

const CouponSkeleton = styled(Skeleton)`
  margin: 0 24px;
  width: 120px;
  height: 24px;
`;

const CheckoutCoupons = () => {
  const allCoupons = useSelector(selectAllCoupons);
  const currentCoupon = useSelector(selectCurrentCoupon);
  const isRestaurantCouponAvailable = useSelector(selectIsRestaurantCouponAvailable);
  const allCouponsLoading = useSelector(selectAllCouponsLoading);
  const isMounted = useIsMounted();

  const shouldShowLoader = useMemo(() => !isMounted() || allCouponsLoading, [allCouponsLoading, isMounted]);

  const onSubmitCouponCode = value => {
    return ManagerProvider.changeCoupon({couponCode: value});
  };

  const onCouponSelect = couponCode => {
    ManagerProvider.changeCoupon({couponCode});
  };

  return (
    <SkeletonLoader shouldShowLoader={shouldShowLoader} LoaderComponent={CouponSkeleton}>
      <CouponModes
        allCoupons={allCoupons}
        currentCoupon={currentCoupon}
        isRestaurantCouponAvailable={isRestaurantCouponAvailable}
        onCouponSelect={onCouponSelect}
        onSubmitCouponCode={onSubmitCouponCode}
      />
    </SkeletonLoader>
  );
};

export default CheckoutCoupons;
