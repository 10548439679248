import {useState, useRef, useMemo, memo} from 'react';

import styled from 'styled-components';
import {useSelector} from 'react-redux';

import {selectUserLoading} from '~/shared/store/selectors';
import {loginWithFacebook} from '~/shared/services/auth';
import {pushRoute, useLocation} from '~/shared/router';
import {CentralLoading} from '~/shared/components/Loaders';
import {createLogger} from '~/shared/logging';
import ManagerProvider from '~/shared/managers/ManagerProvider';
import {trackEvent} from '~/shared/services/analytics';
import {flexColumn} from '~/shared/theme/FlexLayout';
import {LayoutWithDoubleHeader} from '~/shared/components/LayoutWithHeader';
import {FormNamesEnum} from '~/shared/services/analytics/analyticsModels';

import FacebookLoginOrSignUp from './FacebookLoginOrSignUp';
import SignUp from './SignUp';
import Login from './Login';
import {ViewModes} from './consts';

const logger = createLogger('LoginOrRegister');

const screens = {
  login: {
    key: 'login',
    view: Login,
    modeName: ViewModes.SIGN_IN_MODE,
  },
  signUp: {
    key: 'signUp',
    view: SignUp,
    modeName: ViewModes.SIGN_UP_MODE,
  },
  facebook: {
    key: 'facebook',
    view: FacebookLoginOrSignUp,
  },
};

const LoginOrRegisterContainer = styled.div`
  ${flexColumn};
  -webkit-overflow-scrolling: touch;
  ${({pageView}) =>
    !pageView &&
    `
    overflow: auto;
    &&& {
      width: 505px;
    }
  `}
`;

const LoginOrRegister = props => {
  const {currentScreenKey} = props;
  const screen = screens[currentScreenKey];
  const {view: Component, mode} = screen;

  return <Component {...props} mode={mode} />;
};

const LoginOrRegisterWrapper = ({pageView, mode, onSuccess, backButtonUrl, closeModal, isStrongLogin}) => {
  const isUserLoading = useSelector(selectUserLoading);
  const location = useLocation();

  const [currentScreenKey, setCurrentScreenKey] = useState(location.routeParams.mode || mode || screens.login.key);
  const facebookDetails = useRef();
  const [isFB, setIsFB] = useState(false);
  const [fbMode, setFbMode] = useState(ViewModes.SIGN_UP_MODE);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [initialLoginEmail, setInitialLoginEmail] = useState();
  const [isFireRegisterEvent, setIsFireRegisterEvent] = useState(false);

  const isLoading = useMemo(() => isUserLoading || isSubmitting, [isUserLoading, isSubmitting]);

  const openLoginScreen = ({email, isFB: _isFB} = {}) => {
    if (_isFB) {
      setIsFB(true);
      setFbMode(ViewModes.SIGN_IN_MODE);
    }
    if (!_isFB) {
      setCurrentScreenKey(screens.login.key);
    }
    if (email) {
      setInitialLoginEmail(email);
    }
  };

  const openSignUpScreen = () => {
    if (isFB) {
      setFbMode(ViewModes.SIGN_UP_MODE);
    }
    if (!isFB) {
      setCurrentScreenKey(screens.signUp.key);
    }
  };

  const openFacebookScreen = facebookProps => {
    setCurrentScreenKey(screens.facebook.key);
    facebookDetails.current = facebookProps;
    setIsFB(true);
  };

  const onSubmit = async (values, linkType) => {
    try {
      setIsSubmitting(true);
      const userDetails = await ManagerProvider.login(values);
      if (onSuccess) {
        onSuccess(userDetails);
      }
    } catch (error) {
      const errorType = 'login failed';
      logger.error(errorType, {error, fingerprint: errorType});
      if (linkType) {
        trackEvent('hasFormError', {
          formType: '2FA Login Form',
          formErrorType: error?.message,
          linkType,
        });
      }

      trackEvent('formErrorMessageView', {
        formName: linkType === '2FA - SMS' ? FormNamesEnum.OTP_LOGIN : FormNamesEnum.PASSWORD_LOGIN,
        errorType: error?.message,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const onBackButtonClick = () => {
    if (pageView) {
      return pushRoute(backButtonUrl, {keepQuery: false});
    }
    closeModal();
  };

  const openFacebook = async () => {
    if (currentScreenKey === screens.signUp.key) {
      trackEvent('hasClickedFacebookLogin_Signup');
    } else if (currentScreenKey === screens.login.key) {
      trackEvent('hasClickedFacebookLogin_Login');
    }

    const {shouldConnectAccount, facebookUserId, facebookUserAccessToken, encryptedId, email} = await loginWithFacebook();

    if (shouldConnectAccount) {
      openFacebookScreen({
        facebookUserId,
        facebookUserAccessToken,
        shouldConnectAccount: true,
        email,
      });

      return;
    }

    onSubmit({facebookUserId, facebookUserAccessToken, userToken: encryptedId});
  };

  return (
    <LoginOrRegisterContainer pageView={pageView}>
      {isLoading && <CentralLoading />}
      <LayoutWithDoubleHeader
        onClick={onBackButtonClick}
        titleKey="luckily_hunger_brought_you_back"
        secondTitleKey="good_food_is_waiting_for_you_just_around_the_corner"
        showBackArrow={false}
        firstTabActive={isFB ? fbMode === ViewModes.SIGN_IN_MODE : currentScreenKey === screens.login.key}
        onFirstTabClick={() => {
          // login
          trackEvent('hasClickedIHaveAnAccount');
          openLoginScreen({isFB});
          setIsFireRegisterEvent(false);
        }}
        onSecondTabClick={() => {
          trackEvent('hasClickedCreateAccount');
          openSignUpScreen();
          if (!isFireRegisterEvent) {
            trackEvent('hasViewedRegistrationPopup');
            setIsFireRegisterEvent(true);
          }
        }}
        hideHeader={isStrongLogin}
      >
        <LoginOrRegister
          {...{
            isFB,
            setIsFB,
            fbMode,
            isSubmitting,
            setIsSubmitting,
            initialLoginEmail,
            loading: isLoading,
            currentScreenKey,
            openLoginScreen,
            pageView,
            openSignUpScreen,
            onSubmit,
            onSuccess,
            onBackButtonClick,
            openFacebook,
            isStrongLogin,
            ...(facebookDetails.current || {}),
          }}
        />
      </LayoutWithDoubleHeader>
    </LoginOrRegisterContainer>
  );
};

export default memo(LoginOrRegisterWrapper);
