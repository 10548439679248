import {useCallback, memo, useMemo, useEffect} from 'react';

import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {isNull, sumBy, isEmpty} from 'lodash';

import {getLocalizationService} from '~/shared/services/localisationService';
import {SkeletonLoader} from '~/shared/components/Loaders';
import {media, flipOnLTR} from '~/shared/theme/utils';
import {toFixedNum} from '~/shared/utils/general';
import actions from '~/shared/store/actions';
import {useIsMinDesktop} from '~/shared/hooks/deviceInfo';
import {selectCheckoutPaymentsIfExists} from '~/shared/store/selectors';
import {body13Normal} from '~/shared/theme/typography';
import {flexColumn} from '~/shared/theme/FlexLayout';

import CheckoutPaymentMethodLine, {PaymentMethodLineSkeleton} from './CheckoutPaymentMethodLine';

const Root = styled.div`
  margin-top: 16px;
  margin-bottom: 32px;
  padding: ${({theme}) => theme.checkout.elements.paymentsRoot.padding};
  display: block;
  max-height: 100%;
  ${media.minMobile`
    margin-bottom: 16px;
  `}
  ${media.minDesktop`
    max-height: ${({reduceMaxHeight}) => (reduceMaxHeight ? '132px' : '190px')};
    overflow: auto;
    padding: 2px;
  `}
  ${({isEmptyPayments}) => isEmptyPayments && 'margin: 0 0 16px 0;'}
`;

const Payments = styled.div`
  ${flexColumn};
  align-items: flex-start;
`;

const PaymentRemarksForUnderMedium = styled.div`
  width: 100%;
  display: flex;
  margin-top: 16px;
`;

const PaymentRemarkInputForUnderMedium = styled.input`
  outline: none;
  width: 100%;
  height: 50px;
  border: 1px solid ${({theme}) => theme.colors.gray500};
  border-radius: 2px;
  ${body13Normal};
  color: ${({theme}) => theme.colors.text};
  ${flipOnLTR`
    padding-right: 16px;
  `}
`;

const updatePaymentsWithCartChanges = (totalAmount, checkoutPayments, updatePayment) => {
  if (isEmpty(checkoutPayments)) {
    return;
  }

  const firstPayment = checkoutPayments[0];
  const paymentsBesidesFirstPayment = checkoutPayments.filter(
    payment => !payment.isDisabled && payment.cardId !== firstPayment.cardId,
  );

  const paymentsSumBesidesFirstPayment = sumBy(paymentsBesidesFirstPayment, 'sum');

  if (paymentsSumBesidesFirstPayment > 0 || firstPayment.sum === totalAmount) {
    return;
  }

  updatePayment({
    ...firstPayment,
    isDisabled: false,
    sum: toFixedNum(totalAmount, 2),
  });
};

const CheckoutPayments = ({
  isLoading,
  paymentsRemarks,
  setPaymentsRemarks,
  paymentRemarkConfiguration,
  reduceMaxHeight = false,
  reduceBottomMargin = false,
}) => {
  const {t} = getLocalizationService();
  const dispatch = useDispatch();
  const isMinDesktop = useIsMinDesktop();
  const billingLines = useSelector(state => state.shoppingCart?.billingLines);
  const checkoutPayments = useSelector(selectCheckoutPaymentsIfExists);

  const totalAmount = billingLines?.find(({type}) => type === 'TotalToCharge')?.amount || 0;

  const updatePayment = useCallback(
    value => {
      dispatch(actions.updatePayment(value));
    },
    [dispatch],
  );

  useEffect(() => {
    if (checkoutPayments) {
      updatePaymentsWithCartChanges(totalAmount, checkoutPayments, updatePayment);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePayment, totalAmount]);

  const differenceFromOrderSum = useMemo(() => {
    const paymentsSum = checkoutPayments?.reduce((total, payment) => total + (payment.isDisabled ? 0 : payment.sum), 0);

    return toFixedNum(totalAmount - paymentsSum, 2);
  }, [totalAmount, checkoutPayments]);

  const onCardSumInputChange = async ({cardId, inputValue}) => {
    const targetPayment = checkoutPayments.find(payment => payment.cardId === cardId);
    const otherPayments = checkoutPayments.filter(payment => !payment.isDisabled && payment.cardId !== cardId);

    if (inputValue === totalAmount) {
      otherPayments.forEach(payment => {
        updatePayment({
          ...payment,
          sum: 0,
        });
      });
    }

    if (otherPayments.length === 1) {
      updatePayment({
        ...otherPayments[0],
        sum: toFixedNum(totalAmount - inputValue, 2),
      });
    }

    updatePayment({
      ...targetPayment,
      sum: inputValue,
    });
  };

  return (
    <Root isEmptyPayments={!isLoading && checkoutPayments?.length === 0} reduceMaxHeight={reduceMaxHeight}>
      <SkeletonLoader
        shouldShowLoader={isNull(checkoutPayments) || isLoading}
        LoaderComponent={PaymentMethodLineSkeleton}
      >
        <Payments>
          {checkoutPayments?.map(payment => (
            <CheckoutPaymentMethodLine
              key={`${payment.paymentMethod}_${payment.cardId}`}
              showRemoveButton={checkoutPayments.length > 1}
              hasError={false}
              showPaymentsRemarks={!!paymentRemarkConfiguration}
              paymentsRemarks={paymentsRemarks}
              setPaymentsRemarks={setPaymentsRemarks}
              payment={payment}
              differenceFromOrderSum={differenceFromOrderSum}
              totalAmount={totalAmount}
              onCardSumInputChange={onCardSumInputChange}
              isMaxMobile={false}
              isIOS={false}
              t={t}
              customPlaceholder={paymentRemarkConfiguration?.customInputPlaceholder}
              reduceBottomMargin={reduceBottomMargin}
            />
          ))}
        </Payments>
        {!!paymentRemarkConfiguration && !isMinDesktop && (
          <PaymentRemarksForUnderMedium>
            <PaymentRemarkInputForUnderMedium
              placeholder={t(
                paymentRemarkConfiguration?.customInputPlaceholder || 'assign_this_order_to_client_case_number',
              )}
              value={Object.values(paymentsRemarks || {})[0] || ''} // for mobile useage - as we the same value for all payments.
              onChange={({target: {value}}) => setPaymentsRemarks({value})}
            />
          </PaymentRemarksForUnderMedium>
        )}
      </SkeletonLoader>
    </Root>
  );
};

export default memo(CheckoutPayments);
