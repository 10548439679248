import {useEffect, useState, memo} from 'react';

import {useDispatch} from 'react-redux';

import {LoginBody} from '~/shared/components/loginStyled';
import {getLocalizationService} from '~/shared/services/localisationService';
import VerificationForm from '~/shared/components/VerificationForm';
import {VerificationFormContextProvider} from '~/common/contextProviders/VerificationFormContextProvider';

import {
  onForgotPassword,
  onSubmitPassword,
  onSubmitSignUpVerification,
  onSubmitVerification,
  onSignUpSubmit,
  toStepVerificationMode,
} from '../utils/facebookLoginOrSignUpHelper';

import {ViewModes} from './consts';
import PasswordForm from './PasswordForm';
import LoginForm from './LoginForm';
import SignUpForm from './SignUpForm';

const ConnectFbTo10bis = props => {
  const {email} = props;
  return <LoginForm isFB {...props} initialValues={{email}} />;
};

const FbLogin = ({
  isVerificationStep,
  verificationDetails,
  emailEntry,
  onSubmit,
  facebookUserAccessToken,
  facebookUserId,
  setResErrors,
  resErrors,
  setIsVerificationStep,
  isPasswordStep,
  setIsPasswordStep,
  dispatch,
  setEmailEntry,
  setVerificationDetails,
  pageView,
}) => {
  const {t} = getLocalizationService();

  const headerText = verificationDetails?.isPhoneMethod
    ? t('we_sent_you_an_sms_with_code_to_this_number_xxx_withPrefix', {
      lastFourPhoneDigits: verificationDetails?.lastFourPhoneDigits,
    })
    : t('we_sent_you_an_email_with_code_to_verify');
  return (
    <>
      {isVerificationStep && (
        <VerificationFormContextProvider>
          <VerificationForm
            verificationDetails={verificationDetails}
            onReSendCode={() =>
              toStepVerificationMode({
                email: emailEntry,
                setEmailEntry,
                setIsPasswordStep,
                setVerificationDetails,
                setIsVerificationStep,
                setResErrors,
              })}
            onSubmit={({authenticationCode}) =>
              onSubmitVerification({
                onSubmit,
                authenticationCode,
                email: emailEntry,
                facebookUserAccessToken,
                facebookUserId,
                authenticationToken: verificationDetails.authenticationToken,
              })}
            email={emailEntry}
            headerText={headerText}
            t={t}
            setResErrors={setResErrors}
            resErrors={resErrors}
          />
        </VerificationFormContextProvider>
      )}
      {isPasswordStep && (
        <PasswordForm
          onBackClick={() => setIsPasswordStep(false)}
          onSubmitLoginWithEmailAndPassword={({password}) =>
            onSubmitPassword({email: emailEntry, facebookUserAccessToken, facebookUserId, password, onSubmit})}
          entreyEmail={emailEntry}
          onForgotPassword={() => onForgotPassword({email: emailEntry, dispatch})}
          t={t}
          pageView={pageView}
        />
      )}
      {!isVerificationStep && !isPasswordStep && (
        <>
          <ConnectFbTo10bis
            t={t}
            resErrors={resErrors}
            setResErrors={setResErrors}
            email={emailEntry}
            onSubmitEmail={({email}) =>
              toStepVerificationMode({
                email,
                setEmailEntry,
                setIsPasswordStep,
                setVerificationDetails,
                setIsVerificationStep,
                setResErrors,
                t,
              })}
          />
        </>
      )}
    </>
  );
};

const FbSignUp = ({
  isVerificationStep,
  verificationDetails,
  signUpDetails,
  setSignUpDetails,
  setIsVerificationStep,
  setResErrors,
  closeModal,
  dispatch,
  resErrors,
  facebookUserAccessToken,
  facebookUserId,
  openLoginScreen,
}) => {
  const {t} = getLocalizationService();

  return (
    <>
      {isVerificationStep ? (
        <VerificationFormContextProvider>
          <VerificationForm
            verificationDetails={verificationDetails}
            onReSendCode={() =>
              onSignUpSubmit({entries: signUpDetails, setSignUpDetails, setIsVerificationStep, setResErrors})}
            onSubmit={({authenticationCode}) =>
              onSubmitSignUpVerification({
                authenticationCode,
                setResErrors,
                signUpDetails,
                closeModal,
                dispatch,
              })}
            email={signUpDetails?.email}
            headerText={t('we_sent_you_an_sms_with_code_to_this_number_xxx', {phoneNumber: signUpDetails?.cellPhone})}
            setResErrors={setResErrors}
            resErrors={resErrors}
            toUpdatePhoneNumberScreen={() => {
              // todo set phone number on localstate.
              setIsVerificationStep(false);
            }}
          />
        </VerificationFormContextProvider>
      ) : (
        <>
          <SignUpForm
            onSubmit={entries => onSignUpSubmit({entries, setSignUpDetails, setIsVerificationStep, setResErrors})}
            sendActivationCodeError={resErrors}
            initialValues={{
              facebookUserAccessToken,
              facebookUserId,
              cellPhone: signUpDetails?.cellPhone,
              wantPromotion: true,
            }}
            existingEmailAction={email => openLoginScreen({email})}
          />
        </>
      )}
    </>
  );
};

const FacebookLoginOrSignUp = ({
  fbMode,
  facebookUserAccessToken,
  facebookUserId,
  openLoginScreen,
  onSubmit,
  closeModal,
  pageView,
  email,
}) => {
  const [isVerificationStep, setIsVerificationStep] = useState(false);
  const [isPasswordStep, setIsPasswordStep] = useState(false);
  const [emailEntry, setEmailEntry] = useState(email || '');
  const [resErrors, setResErrors] = useState();
  const [verificationDetails, setVerificationDetails] = useState();
  const [signUpDetails, setSignUpDetails] = useState();
  const dispatch = useDispatch();

  const clearSteps = () => {
    setIsVerificationStep(false);
    setIsPasswordStep(false);
  };

  const isLoginMode = fbMode === ViewModes.SIGN_IN_MODE;

  useEffect(() => {
    setResErrors(null);
    clearSteps();
  }, [isLoginMode]);

  return (
    <LoginBody pageView={pageView}>
      {isLoginMode ? (
        <FbLogin
          {...{
            isVerificationStep,
            verificationDetails,
            emailEntry,
            onSubmit,
            facebookUserAccessToken,
            facebookUserId,
            setResErrors,
            resErrors,
            setIsVerificationStep,
            isPasswordStep,
            setIsPasswordStep,
            dispatch,
            setEmailEntry,
            setVerificationDetails,
            pageView,
          }}
        />
      ) : (
        <FbSignUp
          {...{
            isVerificationStep,
            verificationDetails,
            signUpDetails,
            setSignUpDetails,
            setIsVerificationStep,
            setResErrors,
            closeModal,
            dispatch,
            resErrors,
            facebookUserAccessToken,
            facebookUserId,
            openLoginScreen,
          }}
        />
      )}
    </LoginBody>
  );
};

export default memo(FacebookLoginOrSignUp);
