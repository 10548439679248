import {useDispatch, useSelector} from 'react-redux';

import ShoppingCartView, {ShoppingCartProps} from '~/shared/components/ShoppingCart';
import {DishActionProps} from '~/shared/components/ShoppingCart/context';
import {useIsMinLargeMobile} from '~/shared/hooks/deviceInfo';
import {
  selectA11yContrast,
  selectCurrentCoupon,
  selectCurrentDeliveryMethod,
  selectCurrentRestaurant,
  selectDishesAdditionalDetails,
  selectShoppingCartBillingLines,
  selectUserData,
  selectShouldOpenModal,
  selectDishesWithSubs,
  selectFutureOrderAvailableDatesAndTimes,
  selectIsFutureOrderEnabled,
  selectIsShoppingCartHasAgeRestrictionDishOrSub,
  selectCurrentModalName,
  selectDishAssignedUsers,
  isOrderLoadingSelector,
  selectCategoriesListOfCurrentRestaurant,
  DishWithSub,
  selectCategoriesListLoading,
  selectOrderPermit,
  selectIsReorder,
  selectIsDeliveringToCurrentAddress,
  selectIsMinLargeTablet,
  selectCurrentRestaurantShoppingCartDeliveryType,
  selectIsInitialOrderForCurrentRes,
} from '~/shared/store/selectors';
import {selectIsAgeConfirmed} from '~/shared/store/storeModules/ageRestriction/ageRestrictionSelectors';
import actions from '~/shared/store/actions';
import {ACTION_MADE_FROM_ENUM} from '~/shared/utils/ageRestriction';
import {onCheckoutOpen} from '~/shared/services/shoppingCart';
import {openDish} from '~/shared/services/navigation';
import ManagerProvider from '~/shared/managers/ManagerProvider';
import {DishFromCurrentRestaurant, getDishTotalPrice} from '~/shared/utils/billingLinesCalculation';
import {ChoiceToSubmit, ShoppingCartDish} from '~/shared/store/models/ShoppingCart/ShoppingCartDish';
import {trackEvent} from '~/shared/services/analytics';
import {AGE_RESTRICTED_ANALYTICS} from '~/shared/consts/restaurantConsts';
import {removeQueries} from '~/shared/router';
import {EMPTY_ARRAY} from '~/shared/consts/commonConsts';
import {RestaurantBusinessTypeName, RestaurantFromGet} from '~/shared/store/models';
import store from '~/shared/store';
import useIsShoppingCartLoading from '~/shared/hooks/useIsShoppingCartLoading';

import FutureOrderContextProvider from '../FutureOrderContextProvider';

type DishDataForAnalyticsProps = {
  dishesInShoppingCart?: ShoppingCartDish[];
  dishId: number;
  dishName?: string;
  dishSinglePrice: number;
  categoryName?: string;
  dishesWithSubs?: DishWithSub;
  productVariant?: string;
};

const dishDataForAnalytics = ({
  dishesInShoppingCart,
  dishId,
  dishSinglePrice,
  dishName,
  categoryName,
  dishesWithSubs,
  productVariant,
}: DishDataForAnalyticsProps) => {
  if (!dishesInShoppingCart) {
    return {
      productID: dishId,
      productPrice: dishSinglePrice,
      productName: dishName,
      productCategory: categoryName,
      productQuantity: 1,
      productVariant,
    };
  }

  const dishData = dishesWithSubs?.[dishId] as DishFromCurrentRestaurant;
  const choices = dishesInShoppingCart.find(dish => dish.dishId === dishId)?.choices as ChoiceToSubmit[];
  const dishSinglePriceWithSubs = getDishTotalPrice(dishData, choices, 1);
  return {
    productID: dishId,
    productPrice: dishSinglePriceWithSubs,
    productName: dishName,
    productCategory: categoryName,
    productQuantity: 1,
    productVariant,
  };
};

const ShoppingCart = ({
  isMaxHeight = true,
  showProceedButton,
  shouldOpenCheckout,
  showRestaurantHeader,
  showAsMobileButton,
  showTitle,
  loaderComponent,
  className,
  showTotalOrder,
  isCheckoutComponent,
  hideAsapPooledToggler = false,
  isShoppingCartPage,
  onDishListUpdated,
}: ShoppingCartProps & {
  isMaxHeight: boolean;
  hideAsapPooledToggler?: boolean;
  onDishListUpdated?: (...args: any[]) => void;
}) => {
  const dispatch = useDispatch();
  const setCurrentModal = (name: string, args: any) => dispatch(actions.setCurrentModal(name, args));
  const setActionMadeFrom = (actionMadeFrom: ACTION_MADE_FROM_ENUM) =>
    dispatch(actions.setActionMadeFrom(actionMadeFrom));

  const currentRestaurant = useSelector(selectCurrentRestaurant) as RestaurantFromGet;
  const dishes = useSelector(selectDishesAdditionalDetails);
  const userData = useSelector(selectUserData);
  const billingLines = useSelector(selectShoppingCartBillingLines);
  const deliveryMethod = useSelector(selectCurrentDeliveryMethod);
  const currentCoupon = useSelector(selectCurrentCoupon);
  const isDeliveringToCurrentAddress = Boolean(useSelector(selectIsDeliveringToCurrentAddress));
  const shouldOpenModal = useSelector(selectShouldOpenModal);
  const isContrastActive = useSelector(selectA11yContrast);
  const futureOrderAvailableDatesAndTimes = useSelector(selectFutureOrderAvailableDatesAndTimes) || EMPTY_ARRAY;
  const isFutureOrderEnabled = useSelector(selectIsFutureOrderEnabled);
  const dishesWithSubs = useSelector(selectDishesWithSubs);
  const isShoppingCartHasAgeRestrictionDishOrSub = useSelector(selectIsShoppingCartHasAgeRestrictionDishOrSub);
  const isAgeConfirmed = useSelector(selectIsAgeConfirmed);
  const isMinLargeMobile = useIsMinLargeMobile();
  const assignedUsers = useSelector(selectDishAssignedUsers);
  const currentModalName = useSelector(selectCurrentModalName);
  const isDirtyShoppingCart = useSelector(isOrderLoadingSelector);
  const categoriesList = useSelector(selectCategoriesListOfCurrentRestaurant);
  const isMenuLoading = useSelector(selectCategoriesListLoading);
  const permits = useSelector(selectOrderPermit);
  const isReorder = useSelector(selectIsReorder);
  const isMinLargeTablet = useSelector(selectIsMinLargeTablet);
  const shoppingCartDeliveryType = useSelector(selectCurrentRestaurantShoppingCartDeliveryType);
  const isInitialOrder = useSelector(selectIsInitialOrderForCurrentRes);
  const isVoucherCard = currentRestaurant?.businessType === RestaurantBusinessTypeName.VoucherCard;
  const isIncreaseQuantityDisbled = !!currentRestaurant?.isVoucherEnabled || isVoucherCard;

  const showSkeletonLoader = useIsShoppingCartLoading();

  const onDishListQuantityChange = () => {
    if (isShoppingCartPage && onDishListUpdated) {
      // when fired, the dish list is still outdated for the function, should be reselected from the store
      const newDishes = selectDishesAdditionalDetails(store.getState());
      onDishListUpdated(newDishes, dishesWithSubs, categoriesList);
    }
  };

  const decrementDishQuantity =
    ({shoppingCartDishId, dishId, dishSinglePrice, dishName, category, ageRestricted}: DishActionProps) =>
      () => {
        ManagerProvider.decrementDishQuantity(shoppingCartDishId).then(orderData => {
          const removeDishDataForAnalytics = dishDataForAnalytics({
            dishesInShoppingCart: orderData?.data.shoppingCart.dishToSubmitList,
            dishId,
            dishSinglePrice,
            dishName,
            categoryName: category?.categoryName,
            dishesWithSubs,
            productVariant: ageRestricted ? AGE_RESTRICTED_ANALYTICS : '',
          });

          onDishListQuantityChange();
          trackEvent('hasRemovedFromCart', removeDishDataForAnalytics);
        });
      };

  const removeDish =
    ({shoppingCartDishId, dishId, dishSinglePrice, dishName, category, ageRestricted, quantity}: DishActionProps) =>
      async () => {
        await ManagerProvider.removeDish(shoppingCartDishId);
        const removeDishDataForAnalytics = {
          ...dishDataForAnalytics({
            dishId,
            dishSinglePrice,
            dishName,
            categoryName: category?.categoryName,
            dishesWithSubs,
            productVariant: ageRestricted ? AGE_RESTRICTED_ANALYTICS : '',
          }),
          productQuantity: quantity,
        };

        onDishListQuantityChange();
        trackEvent('hasRemovedFromCart', removeDishDataForAnalytics);
      };

  const incrementDishQuantity =
    ({shoppingCartDishId, dishId, dishSinglePrice, dishName, category, ageRestricted}: DishActionProps) =>
      () => {
        ManagerProvider.incrementDishQuantity(shoppingCartDishId).then(orderData => {
          const addDishDataForAnalytics = dishDataForAnalytics({
            dishesInShoppingCart: orderData?.data.shoppingCart.dishToSubmitList,
            dishId,
            dishSinglePrice,
            dishName,
            categoryName: category?.categoryName,
            dishesWithSubs,
            productVariant: ageRestricted ? AGE_RESTRICTED_ANALYTICS : '',
          });

          onDishListQuantityChange();
          trackEvent('hasAddedToCart', addDishDataForAnalytics);
        });
      };

  return (
    <ShoppingCartView.Provider
      value={{
        currentRestaurant,
        dishes,
        userData,
        billingLines,
        deliveryMethod,
        currentCoupon,
        isDeliveringToCurrentAddress,
        shouldOpenModal,
        isContrastActive,
        futureOrderAvailableDatesAndTimes,
        isFutureOrderEnabled,
        dishesWithSubs,
        isShoppingCartHasAgeRestrictionDishOrSub,
        isAgeConfirmed,
        currentModalName,
        isMinLargeMobile,
        assignedUsers,
        isDirtyShoppingCart,
        categoriesList,
        isMaxHeight,
        isMenuLoading,
        permits,
        incrementDishQuantity,
        decrementDishQuantity,
        removeDish,
        openDish,
        setCurrentModal,
        setActionMadeFrom,
        onCheckoutOpen,
        removeQueries,
        isReorder,
        hideAsapPooledToggler,
        isMinLargeTablet,
        shoppingCartDeliveryType,
        openOrderTypeMenuWithError: () => {
          dispatch(actions.setIsOrderTypeMenuOpen(true));
          dispatch(actions.setShowSelectTimeErrorMessage(true));
        },
        isInitialOrder,
        showSkeletonLoader,
        isIncreaseQuantityDisbled,
      }}
    >
      <FutureOrderContextProvider>
        <ShoppingCartView
          {...{
            showProceedButton,
            shouldOpenCheckout,
            showRestaurantHeader,
            showAsMobileButton,
            showTitle,
            loaderComponent,
            className,
            showTotalOrder,
            isCheckoutComponent,
            isShoppingCartPage,
          }}
        />
      </FutureOrderContextProvider>
    </ShoppingCartView.Provider>
  );
};

export default ShoppingCart;
