import {attachModal} from '~/shared/components/ReduxModal';
import {RestaurantCoupons} from '~/coupons';

const RestaurantCouponsModal = props => <RestaurantCoupons {...props} />;

RestaurantCouponsModal.hideCloseButton = true;

attachModal('restaurant_coupons_modal', RestaurantCouponsModal);

export default RestaurantCouponsModal;
