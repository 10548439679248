import styled from 'styled-components';

import {flipOnLTR} from '~/shared/theme/utils';
import {PriceLabel} from '~/shared/components/PriceLabels';

const Root = styled.div`
  display: flex;
  ${flipOnLTR`
    padding-right:5px;
  `};
`;

type DishPriceProps = {
  price: number;
  addParentheses?: boolean;
  className?: string;
  priceDataTestId?: string;
};

const DishPrice = ({price, addParentheses, className, priceDataTestId}: DishPriceProps) => (
  <Root className={className}>
    {addParentheses && '('}
    <PriceLabel price={price} priceDataTestId={priceDataTestId} />
    {addParentheses && ')'}
  </Root>
);

export default DishPrice;
