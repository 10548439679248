import {CouponsState} from '~/shared/store/storeModules/coupons/couponsReducer';

import {Coupon} from '../../models';
import {makeActionCreator, makeThunkAsyncActionCreator} from '../../redux-toolbelt';

export const setCurrentCoupon = makeActionCreator<'setCurrentCoupon', Coupon>('setCurrentCoupon');

export const getAvailableCouponsForOrder = makeThunkAsyncActionCreator<never, CouponsState['availableCoupons']['data']>(
  'getAvailableCouponsForOrder',
  async (_payload, {apiService}) => {
    const availableCoupons = await apiService.getAvailableCouponsForOrder();
    return availableCoupons.data;
  },
);
