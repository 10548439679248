import {snakeCase} from 'lodash';
import styled from 'styled-components';

import {calculateBillingLinesForDisplay} from '~/shared/services/payments';
import {PriceLabel} from '~/shared/components/PriceLabels';
import {getLocalizationService} from '~/shared/services/localisationService';
import {BillingLineType} from '~/shared/consts/checkoutConsts';
import {FlexSpace} from '~/shared/theme/FlexLayout';

import Section from './parts/Section';
import Quantity from './parts/Quantity';

const BillingDetailsWrapper = styled(Section)`
  margin-top: 0;
  padding-top: 0;
  font-weight: bold;
`;

const BoldedTotalCharge = styled(Quantity)`
  font-weight: ${({type}) => (type === BillingLineType.TotalToCharge ? 'bold' : 'normal')};
  width: 100%;
`;

const ColoredBillingDetailLines = styled(FlexSpace)`
  width: 100%;
  color: ${({theme, type}) =>
    (type === BillingLineType.DiscountCoupon ? theme.colors.primary : theme.orderSuccess.body.headerTextColor)};
`;

const BillingLinesDetails = ({isBarCodeOrder, billingLines, className = ''}) => {
  const {t} = getLocalizationService();

  return isBarCodeOrder ? null : (
    <BillingDetailsWrapper className={className}>
      {billingLines &&
        calculateBillingLinesForDisplay(billingLines).map(({amount, type}) => (
          <ColoredBillingDetailLines key={type} type={type} data-test-id={`order_summary_${type}`}>
            <BoldedTotalCharge type={type}>{`${t(`order_success_${snakeCase(type)}`)}`}</BoldedTotalCharge>
            <PriceLabel price={Math.abs(amount)} sign={amount < 0 ? '-' : undefined} type={type} />
          </ColoredBillingDetailLines>
        ))}
    </BillingDetailsWrapper>
  );
};

export default BillingLinesDetails;
